<script setup lang="ts">
import { Note } from '@/types/notes';
import NoteItemControls, {
  NoteActionType,
} from '@/components/notes/item/NoteItemControls.vue';
import { useNoteStore } from '@/stores/notes/useNote';
import IconClose from '@/components/icon/IconClose.vue';
import { shallowRef } from 'vue';

defineEmits<{
  (e: 'check', n: number): void;
  (e: 'action', type: NoteActionType): void;
  (e: 'close', n: number): void;
}>();

const props = withDefaults(
  defineProps<{
    data: Note;
    uncheckable?: boolean;
    isSelected?: boolean;
    selectGroup?: string;
    hideControls?: boolean;
    hideNumber?: boolean;
    closable?: boolean;
    disableOpen?: boolean;
    isStatusChanging?: boolean;
  }>(),
  {
    uncheckable: false,
    isSelected: false,
    hideControls: false,
    hideNumber: false,
    closable: false,
    disableOpen: false,
    isStatusChanging: false,
  },
);

const noteStore = useNoteStore();

const touchStart = shallowRef<Date | null>(null);

const handleTap = () => {
  !props.disableOpen && noteStore.start(props.data.id);
};

const handleTouchstart = () => {
  touchStart.value = new Date();
};

const handleTouchend = () => {
  if (!touchStart.value) return;
  const duration =
    new Date().getMilliseconds() - touchStart.value.getMilliseconds();
  if (duration < 125 && duration > 0) {
    handleTap();
  }
  touchStart.value = null;
};

const stopFn = (e: Event) => e.stopPropagation();
const stopEventHandlers = {
  dragstart: stopFn,
  dragover: stopFn,
  dragenter: stopFn,
  dragleave: stopFn,
  dragend: stopFn,
  touchstart: stopFn,
  touchmove: stopFn,
  touchend: stopFn,
  touchOverNode: stopFn,
};
</script>

<template>
  <div
    class="note-item"
    :class="{
      'is-completed': data.status === 'CLOSE',
      'is-moving': isStatusChanging,
      selected: isSelected,
    }"
    :data-select="data.id"
    :data-select-group="selectGroup"
    @contextmenu.prevent
    @click="!disableOpen && noteStore.start(data.id)"
    @touchstart="handleTouchstart"
    @touchend="handleTouchend"
  >
    <span v-if="!hideNumber" class="note-item__number"
      >№{{ data.priorityNumber }}</span
    >
    <div class="note-item__card">
      <button
        v-if="closable"
        class="note-item__close"
        @click.stop="$emit('close', data.priorityNumber)"
        v-on="stopEventHandlers"
      >
        <IconClose />
      </button>
      <div class="note-item__state">
        <div class="note-item__status" @click.stop v-on="stopEventHandlers">
          <el-checkbox
            v-if="!uncheckable"
            :model-value="data.status === 'CLOSE'"
            class="is-primary"
            @change="$emit('check', data.id)"
          />
        </div>
        <div class="note-item__info">
          <p>{{ data.title }}</p>
          <p>{{ data.description }}</p>
        </div>
      </div>
      <div class="note-item__control" @click.stop v-on="stopEventHandlers">
        <NoteItemControls
          v-if="!hideControls"
          :is-completed="data.status === 'CLOSE'"
          @action="$emit('action', $event)"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.note-item {
  @include flex-row;
  cursor: grab;
  user-select: none;

  &.drag-chosen {
    cursor: grabbing;

    .note-item__number {
      opacity: 0;
      width: 0;
      margin-right: 0;
    }

    &:not(.drag-ghost) {
      .note-item__card {
        rotate: 3.12deg;
      }
    }
  }

  &.drag-ghost {
    .note-item__number {
      opacity: 0;
      width: 0;
      margin-right: 0;
    }
    .note-item__card {
      background: #cbddff;
      border: 1px dashed #778bee;
      & > * {
        visibility: hidden;
      }
    }
  }

  &__number {
    @include f12;
    pointer-events: none;
    line-height: 1.8rem;
    transition: all 0.1s ease;
    margin-right: 1.6rem;
  }

  &__card {
    @include flex-row();
    pointer-events: none;
    position: relative;
    -webkit-user-drag: element;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    width: 100%;
    padding: 1rem;
    background: #fcfcfc;
    box-shadow: 0 0 6px 0 rgba(152, 152, 152, 0.25);
    transform-origin: bottom right;
    border: 1px solid transparent;
    transition: all 0.3s ease;
  }

  &__close {
    position: absolute;
    display: block;
    top: 0;
    line-height: 0;
    right: 0;
    padding: 0.5rem;

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  &.selected {
    .note-item__card {
      border-color: #a3c0f9;
    }
  }

  &__state {
    @include flex-row(1rem);
    flex: 1 1 auto;
  }

  &__info {
    @include flex-column(0.6rem);
    flex: 1 1 auto;
    p:first-child {
      @include f14;
      font-weight: 500;
      line-height: 1.7rem;
    }

    p:last-child {
      @include f14;
      line-height: 1.6rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &__control,
  &__status,
  &__close {
    pointer-events: visible;
  }

  &.is-completed {
    .note-item__info {
      text-decoration: line-through;
    }
  }

  &.is-moving {
    pointer-events: none;
  }
}
</style>

<style lang="scss">
.note-item-move,
.note-item-enter-active,
.note-item-leave-to.is-moving {
  transition: all 0.3s ease;
}

.note-item-enter-from,
.note-item-leave-to.is-moving {
  opacity: 0;
}

.note-item-leave-to.is-moving {
  opacity: 0;
  scale: 0;
}

.note-item-leave-active {
  &:not(.is-moving) {
    opacity: 0;
  }
  position: absolute;
  .note-item__info {
    p:last-child {
      display: none;
    }
  }
}
</style>
