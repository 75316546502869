<script setup lang="ts">
import UiAudioPlayer from '@/components/ui/UIAudioPlayer.vue';
import IconAI from '@/components/icon/IconAI.vue';
import { storeToRefs } from 'pinia';
import { useNoteStore } from '@/stores/notes/useNote';

withDefaults(
  defineProps<{
    readonly?: boolean;
  }>(),
  {
    readonly: false,
  },
);

defineEmits<{
  (e: 'schedule'): void;
}>();

const { audioFile, isAudioLoading, isNoteLoading, isNoteValid, note } =
  storeToRefs(useNoteStore());
</script>

<template>
  <div class="note-card-modal-footer">
    <div
      class="note-card-modal-footer__player"
      v-skeleton="isNoteLoading || isAudioLoading"
    >
      <UiAudioPlayer v-if="audioFile" :audio="audioFile" />
    </div>
    <ui-button
      @click="!readonly && $emit('schedule')"
      :is-disabled="isNoteLoading || !isNoteValid || note.status === 'CLOSE'"
      class="note-card-modal-footer__schedule"
      id="note-schedule"
    >
      <IconAI />
      {{ $t('notes.schedule.one') }}
    </ui-button>
  </div>
</template>

<style scoped lang="scss">
.note-card-modal-footer {
  @include flex-row(2rem);
  justify-content: space-between;
  align-items: center;
  height: 100%;

  &__player {
    border: 1px solid #a3c0f9;
    border-radius: 8px;
  }

  &__schedule {
    @include flex-row(1rem);
    align-items: center;
    max-width: 23rem;
    width: 100%;
  }
}
</style>
