import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "schedule-call-times" }
const _hoisted_2 = { class: "schedule-call-times__content" }

import { RawSuggestedTime } from '@/types/calls';
import TimeItem from '@/components/popups/scheduleCall/TimeItem.vue';
import {
  addMinutes,
  differenceInDays,
  differenceInMinutes,
  format,
  parse,
} from 'date-fns';
import { onUnmounted, ref } from 'vue';

type Props = {
  times: RawSuggestedTime[];
  currentTime: { start: Date; end: Date };
  currentDate: Date;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'ScheduleCallAITimes',
  props: {
    times: {},
    currentTime: {},
    currentDate: {}
  },
  setup(__props: any) {

const props = __props;

const isActive = (time: RawSuggestedTime) => {
  return !(
    differenceInMinutes(time.startTime, props.currentTime.start) ||
    differenceInMinutes(time.finishTime, props.currentTime.end) ||
    differenceInDays(
      parse(time.suggestDate, 'dd.MM.yyyy', new Date()),
      props.currentDate,
    )
  );
};

const setNow = (): RawSuggestedTime => ({
  startTime: new Date(),
  finishTime: addMinutes(new Date(), 30),
  suggestDate: format(new Date(), 'dd.MM.yyyy'),
  better: false,
});
const now = ref(setNow());
const interval = setInterval(() => (now.value = setNow()), 1000);
onUnmounted(() => clearInterval(interval));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createTextVNode(" The best time to call ")),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(TimeItem, {
        time: now.value,
        active: isActive(now.value),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onTimeChange', now.value)))
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode(" Now ")
        ])),
        _: 1
      }, 8, ["time", "active"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.times, (t) => {
        return (_openBlock(), _createBlock(TimeItem, {
          time: t,
          active: isActive(t),
          key: t.startTime,
          onClick: ($event: any) => (_ctx.$emit('onTimeChange', t))
        }, null, 8, ["time", "active", "onClick"]))
      }), 128))
    ])
  ]))
}
}

})