import { ref } from 'vue';
import { AxiosProgressEvent } from 'axios';

export const useUpload = () => {
  const progress = ref<number>();
  const loading = ref(false);
  const control = ref<AbortController>();

  const init = () => {
    loading.value = true;
    progress.value = 0;
    control.value = new AbortController();
  };

  const set = (progressEvent: AxiosProgressEvent) => {
    if (!progressEvent.total) return;
    progress.value = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    );
    if (progress.value === 100) reset();
  };

  const reset = () => {
    loading.value = false;
    progress.value = 0;
    control.value = undefined;
  };

  const abort = () => {
    control.value?.abort();
    reset();
  };

  return { progress, init, set, reset, loading, control, abort };
};
