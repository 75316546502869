import { computed } from 'vue';

export const menu = computed(() => [
  {
    iconName: 'Agenda',
    to: '/agenda/week',
    label: 'menu.agenda',
  },
  {
    iconName: 'Home',
    to: '/home',
    label: 'menu.home',
  },
  {
    iconName: 'Calendar',
    to: '/calendar',
    label: 'menu.calendar',
    coach: 'calendar-tab',
  },
  // {
  //   iconName: 'Files',
  //   to: '/files',
  //   label: 'menu.files',
  // },
  {
    iconName: 'Notes',
    to: '/notes',
    label: 'menu.notes',
  },
  {
    iconName: 'Analytics',
    to: '/analytics',
    label: 'menu.analytics',
    coach: 'analytics',
  },
  {
    iconName: 'Users',
    to: '/users',
    label: 'menu.users',
    coach: 'users-tab',
  },
  {
    iconName: 'Settings',
    to: '/settings',
    label: 'menu.settings',
    coach: 'settings-tab',
  },
]);
