<script setup lang="ts">
import { CallParticipant } from '@/hooks/useUsersSortAndSelect';
import { ref } from 'vue';
import UiInput from '@/components/control/UiInput.vue';
import ParticipantItem from '@/components/popups/scheduleCall/ParticipantItem.vue';

type Props = {
  selectedList: CallParticipant[];
  search: string;
};
defineProps<Props>();
defineEmits(['update:search', 'onDeselect', 'onPaste', 'onEnter']);

const inputRef = ref<InstanceType<typeof UiInput>>();
</script>

<template>
  <div class="selected-participants" @click.stop="inputRef?.input?.focus()">
    <TransitionGroup name="fade-group" appear>
      <div v-for="p in selectedList" :key="p.email">
        <ParticipantItem
          :item="p"
          removable
          @onDeselect="$emit('onDeselect', p)"
        />
      </div>
    </TransitionGroup>
    <ui-input
      :placeholder="selectedList.length ? '' : 'Start typing name or email'"
      ref="inputRef"
      small
      :class="[
        'selected-participants__search',
        { visible: selectedList.length },
      ]"
      :modelValue="search"
      @update:modelValue="$emit('update:search', $event)"
      @paste.stop.prevent="$emit('onPaste', $event)"
      @keyup.enter.stop.prevent="$emit('onEnter', search)"
    />
  </div>
</template>

<style scoped lang="scss">
.selected-participants {
  @include flex-row;
  gap: 0.5rem;
  flex-wrap: wrap;
  align-items: center;
  border: 0.1rem solid #a3c0f9;
  border-radius: 0.8rem;
  padding: 0.4rem 1rem;
  margin-bottom: 1rem;
  transition: all 0.1s ease-in-out;

  &:has(:focus) {
    border-color: var(--color-primary);
  }

  & > * {
    flex: 0 1 auto;
  }

  :deep(.selected-participants__search) {
    border: none;
    padding: 0.3rem;
    width: 20rem;
  }
}
</style>
