<template>
  <ModalNotification
    class="notification-modal help-modal"
    :model-value="isOpen"
    v-bind="$attrs"
    @close="$emit('onClose')"
    width="50rem"
    append-to-body
  >
    <template #header>
      <div class="help-modal__header">
        <p class="help-modal__title">
          {{ $t('support_modals.contact_us.title') }}
        </p>
        <p
          class="help-modal__title help-modal__sub-title"
          v-html="
            $t('support_modals.contact_us.sub_title.text', {
              helper: $t('support_modals.contact_us.sub_title.helper'),
            })
          "
        />
        <span class="help-modal__description">
          {{ $t('support_modals.contact_us.description') }}
        </span>
      </div>
    </template>
    <template #default>
      <div class="help-modal__content">
        <ui-input
          v-model="form.email"
          :label="$t('support_modals.contact_us.form.email')"
          :class="{ error: form.errorEmail }"
          @input="form.errorEmail = false"
        />
        <ui-input
          v-model="form.subject"
          :label="$t('support_modals.contact_us.form.subject')"
          :class="{ error: form.errorSubject }"
          @input="form.errorSubject = false"
        />
        <ui-input
          class="help-modal__textarea"
          type="textarea"
          rows="7"
          v-model="form.description"
          :label="$t('support_modals.contact_us.form.detail')"
          :class="{ error: form.errorDesc }"
          @input="form.errorDesc = false"
        />
      </div>
    </template>
    <template #footer>
      <div class="help-modal__btns">
        <ui-button
          @click="sendFeedback"
          :isDisabled="isLoading"
          :isLoading="isLoading"
        >
          {{ $t('submit') }}
        </ui-button>
        <ui-button
          class="help-modal__link"
          plain
          @click="$emit('openFeedback')"
        >
          {{ $t('support_modals.contact_us.feedback') }}
        </ui-button>
      </div>
    </template>
  </ModalNotification>
</template>
<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import services from '@/api/services';
import { UserTypeFeedback } from '@/types/user';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import ModalNotification from '@/components/modals/ModalNotification.vue';

const { t } = useI18n();

const { userData } = storeToRefs(useUserStore());

defineProps<{
  isOpen: boolean;
}>();

const isLoading = ref(false);

const emit = defineEmits(['onClose', 'openFeedback']);

const form = ref({
  email: '',
  subject: '',
  description: '',
  errorEmail: false,
  errorSubject: false,
  errorDesc: false,
});

const checkValidationForm = () => {
  const { description, email, subject } = form.value;

  if ([description, email, subject].filter(Boolean).length !== 3) {
    if (!description) {
      form.value.errorDesc = true;
    }
    if (!email) {
      form.value.errorEmail = true;
    }
    if (!subject) {
      form.value.errorSubject = true;
    }
    return false;
  }

  return true;
};

const sendFeedback = async () => {
  if (!checkValidationForm()) return;

  isLoading.value = true;
  const { description, email, subject } = form.value;
  const body = {
    description,
    email,
    subject,
  };

  try {
    await services.users.setFeedback(body, UserTypeFeedback.SUPPORT);
    form.value.subject = '';
    form.value.description = '';
    ElNotification.success(t('email_sent'));
    isLoading.value = false;
    emit('onClose');
  } catch (e) {
    ElNotification.error(t('some_error'));
    isLoading.value = false;
  }
};

onMounted(() => {
  form.value.email = userData.value?.email || '';
});

watch(
  () => userData.value,
  () => {
    form.value.email = userData.value?.email || '';
  },
);
</script>
<style lang="scss">
.help-modal {
  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
  }

  &__title {
    @include f16;
    color: #1a2a39;
    text-align: center;
    font-family: 'Unbounded', sans-serif;
    line-height: 2.6rem;
  }

  &__sub-title {
    max-width: 40.6rem;
    margin-bottom: 1.2rem;
    word-break: break-word;
  }

  &__description {
    @include f14;
    color: #1a2a39;
    text-align: center;
    line-height: 2.2rem;
  }

  &__content {
    @include flex-column(2rem);

    &.error {
      border-color: #d64a41;
      animation: shake 0.5s forwards;
      outline: #d64a41;
    }
  }

  &__btns {
    @include flex-row(2rem);
    justify-content: flex-end;
    align-items: center;
    margin-top: 2rem;

    & > * {
      width: 100%;
    }
  }
}

.help-modal__textarea {
  .ui-input__control + label {
    top: 20%;
  }
}
</style>
