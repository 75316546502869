<template>
  <el-popover
    v-model:visible="isListVisible"
    width="fit-content"
    trigger="click"
  >
    <div class="lang-recognition">
      <h3 class="lang-recognition__header">
        {{ $t('create_modal_task.change_lang') }}
      </h3>
      <ui-input v-model="searchLang" :label="$t('search')" icon="search" />
      <transition name="fade" appear mode="out-in">
        <UiSpinner v-if="!finishLoad" />
        <div class="lang-recognition__list" ref="list" v-else>
          <transition-group name="fade-group">
            <div
              v-for="lang in availableList"
              :key="lang.id"
              :class="[
                'lang-recognition__list-item',
                { active: userStore.userData.locale.name === lang.name },
              ]"
              @click="selectVoiceRecognition(lang.name)"
            >
              {{ lang.name }}
              <el-radio
                :value="true"
                :model-value="userStore.userData.locale.name === lang.name"
              />
            </div>
          </transition-group>
        </div>
      </transition>
    </div>
    <template #reference>
      <transition name="blur" mode="out-in">
        <UiSpinner v-if="pending" />
        <div class="lang-recognition__reference" v-else>
          <span class="lang-recognition__current">
            {{ userStore.userData?.locale.name }}
          </span>
          <span class="lang-recognition__desc">
            {{ $t('create_modal_task.select_lange') }}
          </span>
        </div>
      </transition>
    </template>
  </el-popover>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useSettingsStore } from '@/stores/settings/settings';
import { storeToRefs } from 'pinia';
import UiSpinner from '@/components/ui/UiSpinner.vue';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '@/stores/user';

const { t } = useI18n();

const settingsStore = useSettingsStore();
const userStore = useUserStore();

const { voiceRecognitionList, finishLoad } = storeToRefs(settingsStore);

const searchLang = ref('');
const list = ref<HTMLDivElement>();
const pending = ref(false);
const isListVisible = ref(false);

watch(
  () => searchLang.value,
  () => list.value?.scrollTo({ behavior: 'smooth', top: 0 }),
);

const availableList = computed(() =>
  [...voiceRecognitionList.value].sort((a, b) =>
    a.name.toLowerCase().includes(searchLang.value) &&
    !b.name.toLowerCase().includes(searchLang.value)
      ? -1
      : 0,
  ),
);

const selectVoiceRecognition = (lang: string) => {
  pending.value = true;
  try {
    isListVisible.value = false;
    settingsStore.selectVoiceRecognition = lang;
    settingsStore.saveVoiceRecognition();
  } catch (e) {
    console.error(e);
    ElNotification.error({ message: t('some_error'), offset: 80 });
  } finally {
    pending.value = false;
  }
};

onMounted(async () => {
  await settingsStore.getLocaleVoiceData();
  settingsStore.finishLoad = true;
});
</script>
<style lang="scss" scoped>
.lang-recognition {
  @include flex-column(1rem);
  width: 100%;
  height: 25rem;

  &__header {
    @include f14;
  }

  &__list {
    @include flex-column;
    overflow-y: scroll;
    height: 100%;
    max-height: 22rem;

    &-item {
      @include f14;
      @include flex-row;
      align-items: center;
      justify-content: space-between;
      height: 4rem;
      width: 100%;
      padding: 1rem 0.8rem;
      border-radius: 0.8rem;
      @include gray-bg-hover;
      cursor: pointer;

      &.active {
        border-radius: 0.8rem;
        background: #f2f7f7;
      }
    }
  }

  &__reference {
    @include flex-column(1rem);
    align-items: center;
  }

  &__current {
    @include flex-row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    border-radius: 4rem;
    background: #fff;
    padding: 0.4rem 1.2rem;
    border: 0.2rem solid #b8b8d233;
    cursor: pointer;
  }

  &__current,
  &__desc {
    @include f12;
  }
}
</style>
