import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, isRef as _isRef, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@images/calls/schedule-call.svg'
import _imports_1 from '@images/calls/participant.svg'


const _hoisted_1 = { class: "schedule-call__content" }
const _hoisted_2 = { class: "schedule-call__participants" }
const _hoisted_3 = { class: "schedule-call__column with-scroll" }
const _hoisted_4 = { class: "schedule-call__column-head" }
const _hoisted_5 = { class: "schedule-call__column with-scroll" }
const _hoisted_6 = { class: "schedule-call__column-head" }
const _hoisted_7 = { class: "schedule-call__participants-suggested" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "schedule-call__column with-scroll" }
const _hoisted_10 = { class: "schedule-call__column-head" }
const _hoisted_11 = { class: "schedule-call__date" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "schedule-call__participants-control" }
const _hoisted_14 = {
  key: 0,
  class: "counter"
}
const _hoisted_15 = {
  key: 0,
  src: _imports_1,
  alt: ""
}
const _hoisted_16 = { class: "schedule-call__platform" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = ["src"]
const _hoisted_19 = {
  key: 0,
  class: "schedule-call__platform-current"
}
const _hoisted_20 = ["src"]
const _hoisted_21 = ["src"]

import { POPPER_MODIFIERS_DEFAULT } from '@/consts/agenda/popperModifiers';
import IconChevron from '@/components/icon/IconChevron.vue';
import { computed, onMounted, ref, watch } from 'vue';
import ScheduleCallSelectedParticipantsList from '@/components/popups/scheduleCall/ScheduleCallSelectedParticipantsList.vue';
import ScheduleCallParticipantsList from '@/components/popups/scheduleCall/ScheduleCallParticipantsList.vue';
import { useNewTaskStore } from '@/stores/newTask';
import { storeToRefs } from 'pinia';
import UiAvatar from '@/components/ui/UiAvatar.vue';
import IconUsersColored from '@/components/icon/IconUsersColored.vue';
import IconSmart from '@/components/icon/IconSmart.vue';
import ParticipantItem from '@/components/popups/scheduleCall/ParticipantItem.vue';
import { useCallStore } from '@/stores/call';
import IconTime from '@/components/icon/IconTime.vue';
import TimePresets from '@/components/time/TimePresets.vue';
import TimeSelectRange from '@/components/time/TimeSelectRange.vue';
import ScheduleCallAITimes from '@/components/popups/scheduleCall/ScheduleCallAITimes.vue';
import { DatePicker as VDatePicker } from 'v-calendar';
import { MeetingType } from '@/types/calls';
import IconCopy from '@/components/icon/IconCopy.vue';
import { copyToClipboard } from '@/utils/copyToClipboard';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { useIntegrationsStore } from '@/stores/integrations/integrations';

type Props = { isOpen?: boolean; readOnly?: boolean };

export default /*@__PURE__*/_defineComponent({
  __name: 'PopupTaskScheduleCall',
  props: {
    isOpen: { type: Boolean },
    readOnly: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const { t } = useI18n();

const isPlatformsListVisible = ref(false);
const isParticipantsListVisible = ref(false);

const newTask = useNewTaskStore();
const { isSubordinatesFetching } = storeToRefs(newTask);

const call = useCallStore();
const {
  selectedList,
  confirmedState,
  suggestedList,
  list,
  search,
  platformView,
  rawCall,
} = storeToRefs(call);

const onSaveClick = () => {
  isParticipantsListVisible.value = false;
  call.onConfirm();
};

const onCancelClick = () => (isParticipantsListVisible.value = false);

const slicedConfirmedList = computed(() => {
  const visible = confirmedState.value.listParticipants?.slice(0, 3);
  const tailCount = (confirmedState.value.listParticipants?.length || 0) - 3;
  return { visible, tailCount: tailCount > 0 ? `+${tailCount}` : 0 };
});

const integrations = useIntegrationsStore();
const { list: integrationsList } = storeToRefs(integrations);

const onSelectPlatform = (value: MeetingType) => {
  call.onSetPlatform(value);
  isPlatformsListVisible.value = false;
};

const onCopyLink = () => {
  const link = rawCall.value?.linkToMeet;
  if (!link) return;
  copyToClipboard(link);
  ElNotification.success({
    message: t('task_sharing.link_copied'),
    offset: 80,
  });
};

onMounted(() => integrations.fetch());
watch(
  () => [integrationsList.value, props.isOpen],
  () => {
    const type = integrationsList.value.find(
      (item) => item.connection,
    )?.meetingType;
    call.onSetPlatform(type);
  },
  { immediate: true, deep: true },
);

return (_ctx: any,_cache: any) => {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_ui_button = _resolveComponent("ui-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'schedule-call',
      { open: _ctx.isOpen },
      { disabled: _unref(isSubordinatesFetching) },
    ])
  }, [
    (isParticipantsListVisible.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "layout",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (isParticipantsListVisible.value = false))
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "schedule-call__toggle",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:isOpen', !_ctx.isOpen)))
    }, [
      _cache[9] || (_cache[9] = _createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)),
      _cache[10] || (_cache[10] = _createElementVNode("span", null, "Schedule a call", -1)),
      _createVNode(_component_el_switch, {
        class: "green",
        modelValue: _ctx.isOpen
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_popover, {
        width: "850",
        "popper-options": _unref(POPPER_MODIFIERS_DEFAULT),
        visible: isParticipantsListVisible.value,
        "popper-style": "transition: all 0.15s ease-in-out"
      }, {
        reference: _withCtx(() => [
          _createElementVNode("div", {
            class: "schedule-call__participants-current",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (
              !_unref(newTask).currentData.requestId &&
                !_ctx.readOnly &&
                (isParticipantsListVisible.value = true)
            ))
          }, [
            _cache[15] || (_cache[15] = _createElementVNode("span", null, "Participants", -1)),
            _createElementVNode("div", null, [
              _createVNode(_TransitionGroup, {
                name: "fade-group",
                appear: ""
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slicedConfirmedList.value.visible, (p) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: p.email
                    }, [
                      _createVNode(_component_el_tooltip, {
                        content: p.email,
                        effect: "customized",
                        placement: "bottom"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(UiAvatar, {
                            avatarLink: p.avatar?.uuidLink,
                            fallbackName: p.fullName,
                            size: "2.6rem"
                          }, null, 8, ["avatarLink", "fallbackName"])
                        ]),
                        _: 2
                      }, 1032, ["content"])
                    ]))
                  }), 128)),
                  (slicedConfirmedList.value.tailCount)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(slicedConfirmedList.value.tailCount), 1))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              (!slicedConfirmedList.value.visible.length)
                ? (_openBlock(), _createElementBlock("img", _hoisted_15))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, _toDisplayString(_unref(call).confirmedDateWithTimeView), 1)
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(ScheduleCallSelectedParticipantsList, {
              class: "schedule-call__participants-selected",
              selectedList: _unref(selectedList),
              search: _unref(search),
              "onUpdate:search": _cache[2] || (_cache[2] = ($event: any) => (_isRef(search) ? (search).value = $event : null)),
              onOnDeselect: _unref(call).deselectParticipant,
              onOnPaste: _unref(call).onPaste,
              onOnEnter: _unref(call).onEnter
            }, null, 8, ["selectedList", "search", "onOnDeselect", "onOnPaste", "onOnEnter"]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(IconUsersColored),
                _cache[11] || (_cache[11] = _createTextVNode("Users "))
              ]),
              _createVNode(ScheduleCallParticipantsList, {
                list: _unref(list),
                onOnSelect: _unref(call).selectParticipant
              }, null, 8, ["list", "onOnSelect"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(IconSmart),
                _cache[12] || (_cache[12] = _createTextVNode("Suggestions "))
              ]),
              _createElementVNode("div", _hoisted_7, [
                (_unref(suggestedList).length)
                  ? (_openBlock(), _createBlock(_TransitionGroup, {
                      key: 0,
                      name: "fade-group",
                      appear: ""
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(suggestedList), (p) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: p.email
                          }, [
                            _createVNode(ParticipantItem, {
                              item: p,
                              selectable: "",
                              onOnSelect: _unref(call).selectParticipant
                            }, null, 8, ["item", "onOnSelect"])
                          ]))
                        }), 128))
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createElementBlock("span", _hoisted_8, "No suggestions"))
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(IconTime),
                _cache[13] || (_cache[13] = _createTextVNode("Date&Time"))
              ]),
              _createElementVNode("div", _hoisted_11, [
                _cache[14] || (_cache[14] = _createTextVNode(" Date ")),
                _createVNode(_unref(VDatePicker), {
                  modelValue: _unref(call).date,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(call).date) = $event)),
                  locale: _ctx.$i18n.locale
                }, {
                  default: _withCtx(({ togglePopover }) => [
                    _createElementVNode("button", { onClick: togglePopover }, _toDisplayString(_unref(call).dateView), 9, _hoisted_12)
                  ]),
                  _: 1
                }, 8, ["modelValue", "locale"])
              ]),
              _createVNode(TimePresets, {
                onSetPreset: _cache[4] || (_cache[4] = ($event: any) => {
                _unref(call).onSetPreset($event);
                _unref(call).fetchSuggestedTimesList();
              }),
                start: _unref(call).time.start,
                end: _unref(call).time.end
              }, null, 8, ["start", "end"]),
              _createVNode(TimeSelectRange, {
                startTime: _unref(call).time.start,
                "onUpdate:startTime": _cache[5] || (_cache[5] = ($event: any) => ((_unref(call).time.start) = $event)),
                endTime: _unref(call).time.end,
                "onUpdate:endTime": _cache[6] || (_cache[6] = ($event: any) => ((_unref(call).time.end) = $event))
              }, null, 8, ["startTime", "endTime"]),
              _createVNode(ScheduleCallAITimes, {
                times: _unref(call).timesList,
                currentTime: _unref(call).time,
                currentDate: _unref(call).date,
                onOnTimeChange: _unref(call).onSetTime
              }, null, 8, ["times", "currentTime", "currentDate", "onOnTimeChange"])
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_ui_button, {
              plain: "",
              onClick: onCancelClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ui_button, { onClick: onSaveClick }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('save')), 1)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["popper-options", "visible"]),
      _createVNode(_component_el_popover, {
        trigger: "click",
        width: "200",
        "popper-style": "padding: 0",
        "popper-options": _unref(POPPER_MODIFIERS_DEFAULT),
        visible: isPlatformsListVisible.value,
        "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => ((isPlatformsListVisible).value = $event))
      }, {
        reference: _withCtx(() => [
          (!_ctx.readOnly)
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _createElementVNode("img", {
                  src: _unref(platformView)?.icon,
                  alt: ""
                }, null, 8, _hoisted_20),
                _createTextVNode(" " + _toDisplayString(_unref(platformView)?.label || 'Choose platform') + " ", 1),
                _createVNode(IconChevron, {
                  direction: isPlatformsListVisible.value ? 'top' : 'bottom'
                }, null, 8, ["direction"])
              ]))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_16, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(integrations).options, ({
              value,
              label,
              icon,
              authLabel,
              auth,
            }) => {
              return (_openBlock(), _createElementBlock("div", {
                key: label,
                onClick: ($event: any) => (
              _unref(integrations).getActive(value) ? onSelectPlatform(value) : auth()
            )
              }, [
                _createElementVNode("img", {
                  src: icon,
                  alt: ""
                }, null, 8, _hoisted_18),
                _createTextVNode(" " + _toDisplayString(_unref(integrations).getActive(value) ? label : authLabel), 1)
              ], 8, _hoisted_17))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["popper-options", "visible"]),
      (_ctx.readOnly)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "schedule-call__platform-view",
            onClick: onCopyLink
          }, [
            _createElementVNode("img", {
              src: _unref(platformView)?.icon,
              alt: ""
            }, null, 8, _hoisted_21),
            _createTextVNode(" " + _toDisplayString(_unref(platformView)?.label || 'Undefined') + " ", 1),
            _createVNode(IconCopy)
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})