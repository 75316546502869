import { ApiService } from '@/api/services/core';
import { axiosInstance } from '@/api/axios';
import { ENDPOINTS } from '@/api/endpoints';

export class ZoomIntegrationService extends ApiService {
  getAuthorizationLink() {
    return axiosInstance.get<string>(ENDPOINTS.auth.zoomAuthLink);
  }

  sendCode(params: { code: string; state: number }) {
    return axiosInstance.get(ENDPOINTS.auth.zoomAuthCode, {
      params,
    });
  }
}
