import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

import { useRoute } from 'vue-router';
import { computed, provide, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useSettingsStore } from '@/stores/settings/settings';
import { storeToRefs } from 'pinia';
import { useHead } from '@vueuse/head';
import { useWindowSize } from '@vueuse/core';
import { useIntegrationsStore } from '@/stores/integrations/integrations';
import { useCallStore } from '@/stores/call';

const title = 'Log in to Voiset I seamless task management with AI';
const DEFAULT_LAYOUT = 'default';

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

useHead({
  title,
  meta: [
    {
      key: 'og:title',
      property: 'og:title',
      content: title,
    },
  ],
});

const route = useRoute();
const i18t = useI18n();
const { lang } = storeToRefs(useSettingsStore());

const { width: windowWidth } = useWindowSize();
provide('windowWidth', windowWidth);

const isMobileResolution = computed(() => windowWidth.value <= 1100);
provide('isMobileResolution', isMobileResolution);

const layout = computed(
  () => (route.meta?.layout || DEFAULT_LAYOUT) + '-layout',
);

const channel = new BroadcastChannel('voiset');
channel.onmessage = async (event) => {
  if (event.data.type === 'zoom-update') {
    const integrations = useIntegrationsStore();
    const call = useCallStore();
    console.log('Zoom auth tab is closed');
    await integrations.fetch();
    if (integrations.getActive('ZOOM')) call.onSetPlatform('ZOOM');
  }
};

watch(
  () => lang.value,
  (newLang) => newLang && (i18t.locale.value = newLang.toLowerCase()),
);

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(layout.value), null, {
    default: _withCtx(() => [
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          _createVNode(_Transition, {
            name: "fade",
            mode: "out-in",
            appear: ""
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})