import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { useVModel } from '@vueuse/core';


export default /*@__PURE__*/_defineComponent({
  __name: 'NoteCardBody',
  props: {
    readonly: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    title: {},
    description: {}
  },
  emits: ['update:title', 'update:description'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const titleModel = useVModel(props, 'title', emit);
const descriptionModel = useVModel(props, 'description', emit);

return (_ctx: any,_cache: any) => {
  const _component_ui_input = _resolveComponent("ui-input")!
  const _directive_skeleton = _resolveDirective("skeleton")!

  return (_openBlock(), _createElementBlock("form", {
    class: "note-card-body",
    onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
  }, [
    _withDirectives(_createVNode(_component_ui_input, {
      modelValue: _unref(titleModel),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(titleModel) ? (titleModel).value = $event : null)),
      placeholder: _ctx.$t('notes.creating.name_placeholder'),
      readonly: _ctx.readonly
    }, null, 8, ["modelValue", "placeholder", "readonly"]), [
      [
        _directive_skeleton,
        _ctx.isLoading,
        void 0,
        { large: true }
      ]
    ]),
    _withDirectives(_createVNode(_component_ui_input, {
      modelValue: _unref(descriptionModel),
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(descriptionModel) ? (descriptionModel).value = $event : null)),
      placeholder: _ctx.$t('notes.creating.description_placeholder'),
      type: "textarea",
      rows: "8",
      class: "note-card-body__area",
      readonly: _ctx.readonly,
      style: {"resize":"vertical"}
    }, null, 8, ["modelValue", "placeholder", "readonly"]), [
      [
        _directive_skeleton,
        _ctx.isLoading,
        void 0,
        { large: true }
      ]
    ])
  ], 32))
}
}

})