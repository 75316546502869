<script lang="ts" setup>
import WidgetShareTask from '@/components/widget/WidgetShareTask.vue';
import IconShare from '@/components/icon/IconShare.vue';
import IconMessage from '@/components/icon/IconMessage.vue';
import IconClose from '@/components/icon/IconClose.vue';
import { computed, ref } from 'vue';
import HeaderActionButton from './HeaderActionButton.vue';
import IconQuestion from '@/components/icon/IconQuestion.vue';
import { useCoachesStore } from '@/stores/coaches/coaches';
import IconFavorite from '@/components/icon/IconFavorite.vue';
import { useViewTaskStore } from '@/stores/viewTask/viewTask';
import { storeToRefs } from 'pinia';
import { useDebounceFn } from '@vueuse/core';
import IconTrash from '@/components/icon/IconTrash.vue';
import { useUserStore } from '@/stores/user';
import ModalNotification from '@/components/modals/ModalNotification.vue';
import { useTasksStore } from '@/stores/tasks/tasks';

defineProps<{ readOnly?: boolean }>();

const toggleComments = () => {
  if (state.value.isShowComments) {
    viewTask.closeComments();
    useDebounceFn(viewTask.clearComments, 500)();
  } else {
    viewTask.openComments();
    viewTask.getComments();
  }
};

const couchStore = useCoachesStore();
const shareLink = ref(false);

const viewTask = useViewTaskStore();
const { state, activeTask, upload } = storeToRefs(viewTask);

const user = useUserStore();
const { userData } = storeToRefs(user);

const tasks = useTasksStore();

const isDeletable = computed(
  () => activeTask.value.assigner?.id === userData.value?.id,
);
const isConfirmDeletionOpen = ref(false);
const onDeletionConfirmed = async () => {
  isConfirmDeletionOpen.value = false;
  if (!activeTask.value.id) return;
  await viewTask.remove(activeTask.value);
  await viewTask.clear();
  await tasks.updateTasksList();
};

const setShareLinkVisible = (val: boolean) => (shareLink.value = val);

const onClose = () => {
  state.value.isOpenModal && viewTask.clear();
  upload.value.abort();
};
</script>

<template>
  <div :class="$style.wrapper">
    <HeaderActionButton
      v-if="isDeletable && !readOnly"
      tooltip="Delete task"
      @click="isConfirmDeletionOpen = true"
    >
      <IconTrash width="20" />
    </HeaderActionButton>
    <el-tooltip
      v-if="!state.isShared"
      content="Add task to favourites"
      placement="bottom"
      :disabled="viewTask.activeTask.isMarked"
      effect="customized"
    >
      <IconFavorite
        :class="$style.favorite"
        :isActive="viewTask.activeTask.isMarked"
        @click="viewTask.setActiveTaskFields('isMarked', !activeTask.isMarked)"
      />
    </el-tooltip>
    <HeaderActionButton
      :active="state.isShowComments"
      :tooltip="
        $t(
          !state.isShowComments
            ? 'view_modal_task.comments'
            : 'view_modal_task.hide_comments',
        )
      "
      @click="toggleComments"
    >
      <IconMessage />
    </HeaderActionButton>
    <el-popover
      v-if="!state.isShared && !state.isTaskLoading"
      :visible="shareLink"
      @update:visible="setShareLinkVisible"
      placement="bottom"
      popper-class="share__popup"
      width="100%"
      trigger="click"
    >
      <WidgetShareTask @on-close="setShareLinkVisible(false)" />
      <template #reference>
        <div>
          <HeaderActionButton
            v-if="!state.isTaskLoading"
            :tooltip="$t('view_modal_task.share')"
          >
            <IconShare />
          </HeaderActionButton>
        </div>
      </template>
    </el-popover>
    <HeaderActionButton
      :tooltip="$t('tooltips')"
      :active="couchStore.isCoachesEnabled"
      @click="couchStore.resolveCoach"
    >
      <IconQuestion width="18" height="18" />
    </HeaderActionButton>
    <button v-if="!state.isShared" :class="$style.close" @click="onClose">
      <IconClose />
    </button>
    <ModalNotification
      append-to-body
      :is-open="isConfirmDeletionOpen"
      message="In this case, the task and all information will be deleted forever."
      yes="Delete"
      width="40rem"
      @on-close="isConfirmDeletionOpen = false"
      @on-confirm="onDeletionConfirmed"
    >
      <template #header>
        <div :class="$style.deleteConfirmHeader">
          <IconTrash color="var(--color-danger)" />
          Do you want to delete the task?
        </div>
      </template>
    </ModalNotification>
  </div>
</template>

<style lang="scss" module>
.wrapper {
  height: 3rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: auto;
}
.btn {
  display: flex;
  align-items: center;
  @include f14;
  line-height: 1.1;
  min-height: 2.8rem;
  border-radius: 6px;
  transition: all 0.1s linear;
  padding: 0 1rem;
  margin-right: 1rem;

  &:hover {
    background-color: #ecfadb;
  }

  svg {
    margin-right: 0.85rem;
  }

  &.share {
    margin-right: 1.25rem;
  }
}

.close {
  width: 2.8rem;
  height: 2.8rem;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.favorite {
  cursor: pointer;
  outline: none;
}
.deleteConfirmHeader {
  @include flex-row(1rem);
  align-items: center;
  justify-content: center;
}
</style>
