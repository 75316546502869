import { defineStore } from 'pinia';
import { ref } from 'vue';
import services from '@/api/services';

export const useZoomStore = defineStore('zoom', () => {
  const pending = ref(false);

  const signup = async () => {
    return (await services.integrations.zoom.getAuthorizationLink()).data;
  };

  const auth = async (payload: { code: string; state: number }) => {
    return await services.integrations.zoom.sendCode(payload);
  };

  return { pending, signup, auth };
});
