import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"
import _imports_0 from '@images/record/record-template.webp'
import _imports_1 from '@images/record/record-active.webp'


const _hoisted_1 = {
  class: "player",
  id: "note-record"
}
const _hoisted_2 = { class: "player__timer" }
const _hoisted_3 = { class: "player__timer" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = {
  key: 0,
  src: _imports_1,
  class: "player__record-active",
  alt: ""
}
const _hoisted_6 = {
  key: 1,
  src: _imports_0,
  alt: ""
}
const _hoisted_7 = {
  key: 0,
  class: "player__loading"
}

import { computed, onMounted, ref, toRef, watch } from 'vue';
import IconPlay from '@/components/icon/IconPlay.vue';
import IconPause from '@/components/icon/IconPause.vue';
import IconSpinner from '@/components/icon/IconSpinner.vue';
import IconMicrophone from '@/components/icon/IconMicrophone.vue';
import { useI18n } from 'vue-i18n';
import { sleep } from '@/utils/common';
import services, { sockets } from '@/api/services';
import { VoiceKeyResponse } from '@/types/voice';
import { watchDebounced } from '@vueuse/core';
import { useUserStore } from '@/stores/user';
import { useAudioPlayer } from '@/hooks/useAudioPlayer';
import { formatSeconds } from '@/utils/formatSeconds';

type Props = {
  audio: {
    recordFileRuntime: string | null;
    recordTime: number | null;
    isRecording: boolean;
    recordError: boolean;
    runtimeSpeech: string;
    blobData?: Blob;
    stopRecording: () => Promise<void>;
    startRecording: () => Promise<void>;
  };
};


export default /*@__PURE__*/_defineComponent({
  __name: 'UiAudioRecorder',
  props: {
    audio: {}
  },
  emits: ['onStopRecord', 'onStartRecord'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit;

const audio = toRef(props, 'audio');

const { t } = useI18n();

const { initAudioPlayer, play, pause, isPlaying, playerTime } =
  useAudioPlayer();

const userStore = useUserStore();

const isLoadingAudio = ref(false);
const voiceKey = ref<VoiceKeyResponse>();

const currentRecordTime = computed(() => {
  const seconds = Number(audio.value.recordTime || 0);
  return formatSeconds(seconds);
});

const onStopRecord = async () => {
  isLoadingAudio.value = true;
  await sleep(1500);
  isLoadingAudio.value = false;

  audio.value.stopRecording?.();
  sockets.speech.close();
  voiceKey.value?.id &&
    (await services.voice.finishRecord(
      voiceKey.value.id,
      Math.round((audio.value.recordTime || 0) * 1000),
    ));

  voiceKey.value = (
    await services.voice.getVoiceApiKey(userStore.userData?.locale.id)
  ).data;

  emits('onStopRecord');
};

watchDebounced(
  () => [audio.value.runtimeSpeech, audio.value.isRecording],
  () => audio.value.isRecording && onStopRecord(),
  { debounce: 5000 },
);

const startRecord = async () => {
  if (audio.value.isRecording) return await onStopRecord();
  emits('onStartRecord');

  try {
    isLoadingAudio.value = true;
    voiceKey.value &&
      (await sockets.speech.connect({
        token: voiceKey.value.voiceToken.uuid,
      }));
    await audio.value.startRecording?.();
  } catch (e) {
    console.error(e);
    // if (e.response?.status === 406) audio.value.recordError = true;
  } finally {
    isLoadingAudio.value = false;
  }
};

watch(
  () => audio.value.blobData,
  (val) => sockets.speech.socketInstance.send(val),
);
watch(
  () => audio.value.recordFileRuntime,
  () => initAudioPlayer(audio.value.recordFileRuntime),
);

const recordTooltip = computed(() =>
  audio.value.recordError
    ? t('create_modal_task.record_error')
    : t('create_modal_task.record'),
);

onMounted(
  async () =>
    (voiceKey.value = (
      await services.voice.getVoiceApiKey(userStore.userData?.locale.id)
    ).data),
);

return (_ctx: any,_cache: any) => {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (audio.value.recordFileRuntime)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(playerTime)), 1),
          _createElementVNode("button", {
            class: "player__record-btn",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(isPlaying) ? _unref(pause)() : _unref(play)()))
          }, [
            _cache[1] || (_cache[1] = _createElementVNode("img", {
              src: _imports_0,
              alt: ""
            }, null, -1)),
            (_unref(isPlaying))
              ? (_openBlock(), _createBlock(IconPause, {
                  key: 0,
                  class: "player__control"
                }))
              : (_openBlock(), _createBlock(IconPlay, {
                  key: 1,
                  class: "player__control",
                  style: {"left":"4.2rem"}
                }))
          ])
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(currentRecordTime.value), 1),
          _createVNode(_component_el_tooltip, {
            content: recordTooltip.value,
            effect: "customized",
            placement: "top-start"
          }, {
            default: _withCtx(() => [
              _createElementVNode("button", {
                disabled: isLoadingAudio.value,
                class: "player__record-btn",
                onClick: startRecord
              }, [
                _createVNode(_Transition, {
                  name: "blur",
                  appear: "",
                  mode: "out-in"
                }, {
                  default: _withCtx(() => [
                    (audio.value.isRecording)
                      ? (_openBlock(), _createElementBlock("img", _hoisted_5))
                      : (_openBlock(), _createElementBlock("img", _hoisted_6))
                  ]),
                  _: 1
                }),
                (isLoadingAudio.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(IconSpinner, {
                        circleColor: "#ffffff",
                        color: "#ffffff"
                      })
                    ]))
                  : (_openBlock(), _createBlock(IconMicrophone, { key: 1 }))
              ], 8, _hoisted_4)
            ]),
            _: 1
          }, 8, ["content"])
        ], 64))
  ]))
}
}

})