export const createNumberedArray = (length: number) =>
  Array.from({ length }, (v, i) => i + 1);

export const numericSort = <T extends Record<K, T[K]>, K extends keyof T>(
  array: T[],
  prop: K,
  direction: 'ASC' | 'DESC' = 'ASC',
) => {
  const d = direction === 'DESC' ? -1 : 1;
  return array.sort((a, b) => (a[prop] - b[prop]) * d);
};

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

const numberToHexPaletteMap: Record<number, string> = {
  0: '#D9D9D9',
  1: '#FFADE9',
  2: '#CFF1A2',
  3: '#B2BFFF',
  4: '#FFD698',
  5: '#ADE6FF',
  6: '#D2B9FF',
  7: '#A3F9D8',
  8: '#F2E68C',
  9: '#F5AAA1',
};

export const numberToColor = (id?: number) => {
  if (!id) return numberToHexPaletteMap[0];

  while (id >= 10) {
    id = id
      .toString()
      .split('')
      .reduce((sum, digit) => sum + Number(digit), 0);
  }
  return numberToHexPaletteMap[id];
};

export const stringToColor = (string: string) => {
  let hash = 0;
  string.split('').forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += value.toString(16).padStart(2, '0');
  }
  color += 'BF';
  return color;
};
