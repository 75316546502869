import { ApiService } from '@/api/services/core';
import { axiosInstance } from '@/api/axios';
import { ENDPOINTS } from '@/api/endpoints';
import {
  RawSuggestedTime,
  RawSuggestedUser,
  SuggestedTimesRequestParams,
} from '@/types/calls';

export class CallsService extends ApiService {
  getSuggestedUsers(userId: number) {
    return axiosInstance.get<RawSuggestedUser[]>(ENDPOINTS.calls.suggested, {
      params: { userId },
    });
  }

  getSuggestedTimes(payload: SuggestedTimesRequestParams) {
    return axiosInstance.post<RawSuggestedTime[]>(
      ENDPOINTS.calls.time,
      payload,
    );
  }
}
