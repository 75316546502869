import { ref } from 'vue';

export type TaskState = {
  isOpenModal: boolean;
  isShared: boolean;
  invalidSharedLink: boolean;
  hasSharedAccess: boolean;
  isFileLoading: boolean;
  isTaskLoading: boolean;
  isAudioLoading: boolean;
  isShowComments: boolean;
  hasScheduledCall: boolean;
};

export const useTaskState = () => {
  const defaultState = () => ({
    isOpenModal: false,
    isShared: false,
    invalidSharedLink: false,
    hasSharedAccess: true,
    isFileLoading: true,
    isTaskLoading: true,
    isAudioLoading: false,
    isShowComments: false,
    hasScheduledCall: false,
  });
  const state = ref<TaskState>(defaultState());
  const setState = (options?: Partial<TaskState>) =>
    (state.value = { ...state.value, ...options });
  const resetState = (options?: Partial<TaskState>) =>
    (state.value = { ...defaultState(), ...options });
  const openModal = (options?: Partial<TaskState>) =>
    setState({ ...options, isOpenModal: true });

  return { state, setState, resetState, openModal };
};
