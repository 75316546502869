import { ApiService } from '@/api/services/core';
import { axiosInstance } from '@/api/axios';
import { ENDPOINTS } from '@/api/endpoints';
import { FeedbackBody, UserTypeFeedback } from '@/types/user';
import { IntegrationAccount } from '@/types/integrations';
import {
  ExternalService,
  ExternalServiceRequestParams,
} from '@/types/external-service';

export class UsersService extends ApiService {
  setFeedback(feedback: FeedbackBody, typeRequest: UserTypeFeedback) {
    return axiosInstance.post(ENDPOINTS.users.setFeedback, feedback, {
      params: {
        typeRequest,
      },
    });
  }

  getIntegrations() {
    return axiosInstance.get<IntegrationAccount[]>(
      ENDPOINTS.users.integrations,
    );
  }

  getExternalServices() {
    return axiosInstance.get<ExternalService[]>(ENDPOINTS.users.productivity);
  }

  setExternalService(params: ExternalServiceRequestParams) {
    return axiosInstance.post<ExternalService>(
      ENDPOINTS.users.productivity,
      null,
      { params },
    );
  }
}
