<script setup lang="ts">
import UiModal from '@/components/ui/UiModal.vue';
import { useVModel } from '@vueuse/core';
import { useNoteStore } from '@/stores/notes/useNote';
import { storeToRefs } from 'pinia';
import NoteCardModalFooter from '@/components/notes/card/NoteCardModalFooter.vue';
import NoteItemControls, {
  NoteActionType,
} from '@/components/notes/item/NoteItemControls.vue';
import { useNotesTransformStore } from '@/stores/notes/useNotesTransform';
import NoteCardBody from '@/components/notes/card/NoteCardBody.vue';
import WidgetCoach from '@/components/widget/WidgetCoach.vue';
import { useCoachesStore } from '@/stores/coaches/coaches';
import { ref, watch } from 'vue';
import { CoachName } from '@/types/coaches';

const props = defineProps<{
  modelValue: boolean;
}>();
const emit = defineEmits(['update:modelValue']);

const isOpen = useVModel(props, 'modelValue', emit);

const noteStore = useNoteStore();
const { isStarted, note, isNoteLoading, initialNote } = storeToRefs(noteStore);

const notesTransformStore = useNotesTransformStore();

const handleSchedule = () => {
  if (!initialNote.value) return;
  isStarted.value = false;
  notesTransformStore.start([initialNote.value]);
};

const handleAction = (type: NoteActionType) => {
  if (type === 'done' || type === 'back') {
    note.value.status = type === 'done' ? 'CLOSE' : 'ACTIVE';
  } else if (type === 'schedule') {
    handleSchedule();
  }
};

const coachesStore = useCoachesStore();
const { setCoach } = coachesStore;
const { activeCoach } = storeToRefs(coachesStore);

const cashedCoach = ref<CoachName | null>(null);

watch(
  () => isOpen.value,
  (value) => {
    if (value) {
      cashedCoach.value = activeCoach.value.name;
      setCoach('scheduleNote');
    } else {
      cashedCoach.value && setCoach(cashedCoach.value);
    }
  },
);
</script>

<template>
  <UiModal
    :is-open="isOpen"
    @on-close="noteStore.end()"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :close-disabled="true"
    :title="$t('notes.one')"
    class="note-card-modal"
  >
    <template #headerActions>
      <NoteItemControls
        v-if="!isNoteLoading"
        :is-completed="note.status === 'CLOSE'"
        @action="handleAction"
      />
      <WidgetCoach />
    </template>
    <template #body>
      <NoteCardBody
        v-model:title="note.title"
        v-model:description="note.description"
        :is-loading="isNoteLoading"
      />
    </template>
    <template #footer>
      <NoteCardModalFooter @schedule="handleSchedule" />
    </template>
  </UiModal>
</template>
