<script setup lang="ts">
import { useVModel } from '@vueuse/core';

const props = withDefaults(
  defineProps<{
    readonly?: boolean;
    isLoading?: boolean;
    title: string;
    description: string;
  }>(),
  {
    readonly: false,
    isLoading: false,
  },
);

const emit = defineEmits(['update:title', 'update:description']);

const titleModel = useVModel(props, 'title', emit);
const descriptionModel = useVModel(props, 'description', emit);
</script>

<template>
  <form class="note-card-body" @submit.prevent>
    <ui-input
      v-model="titleModel"
      :placeholder="$t('notes.creating.name_placeholder')"
      :readonly="readonly"
      v-skeleton.large="isLoading"
    />
    <ui-input
      v-model="descriptionModel"
      :placeholder="$t('notes.creating.description_placeholder')"
      type="textarea"
      rows="8"
      class="note-card-body__area"
      :readonly="readonly"
      style="resize: vertical"
      v-skeleton.large="isLoading"
    />
  </form>
</template>

<style scoped lang="scss">
.note-card-body {
  height: 100%;
  padding-bottom: 10rem;
  @include flex-column(1.6rem);

  &__area {
    flex: 1;
    :deep(textarea) {
      height: 100%;
    }
  }
}
</style>
