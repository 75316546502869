import { defineStore, storeToRefs } from 'pinia';
import {
  CallParticipant,
  useUsersSortAndSelect,
} from '@/hooks/useUsersSortAndSelect';
import { computed, ref } from 'vue';
import {
  addHours,
  addMinutes,
  differenceInMinutes,
  differenceInSeconds,
  format,
  parse,
  setMinutes,
} from 'date-fns';
import services from '@/api/services';
import { concatName } from '@/utils/text';
import { useNewTaskStore } from '@/stores/newTask';
import {
  CallEvent,
  CallEventConfirmedParams,
  CallEventRemoveParams,
  CallEventRequestParams,
  MeetingType,
  RawSuggestedTime,
} from '@/types/calls';
import { PLATFORMS } from '@/consts/calls';
import {
  formatCallTime,
  setCallTimeToCurrentUTC,
  setCallTimeToUTC0,
} from '@/utils/time';
import { useUserStore } from '@/stores/user';
import { useIntegrationsStore } from '@/stores/integrations/integrations';

export const useCallStore = defineStore('call', () => {
  const rawCall = ref<CallEvent>();
  const initialTime = () => {
    const shouldRoundUp = new Date().getMinutes() % 15;
    const start = addMinutes(new Date(), 15 - shouldRoundUp);

    return {
      start,
      end: addMinutes(start, 30),
    };
  };
  const time = ref<{ start: Date; end: Date }>(initialTime());
  const timeRange = computed(() =>
    differenceInSeconds(time.value.end, time.value.start),
  );
  const date = ref(new Date());
  const dateView = computed(() => format(date.value, 'EEEE, MMMM d, yyyy'));
  const confirmedDateWithTimeView = computed(
    () =>
      `${format(confirmedState.value.dateMeetingStart, 'EEE, MMM d')} ${format(
        confirmedState.value.dateMeetingStart,
        'HH:mm',
      )} - ${format(confirmedState.value.dateMeetingEnd, 'HH:mm')} `,
  );

  const timesList = ref<RawSuggestedTime[]>([]);
  const onSetTime = (rawTime: RawSuggestedTime) => {
    time.value.start = new Date(rawTime.startTime);
    time.value.end = new Date(rawTime.finishTime);
    date.value = parse(rawTime.suggestDate, 'dd.MM.yyyy', new Date());
  };
  const onSetPreset = (minutes: number) =>
    (time.value.end = addMinutes(time.value.start, minutes));

  const platformView = computed(() =>
    PLATFORMS.find((p) => p.value === confirmedState.value.meetingType),
  );
  const onSetPlatform = (p: MeetingType | undefined) =>
    (confirmedState.value.meetingType = p);

  const {
    onSelect,
    onDeselect,
    onPaste,
    onEnter,
    onReset,
    setInitSort,
    selectedList,
    suggestedList,
    list,
    search,
  } = useUsersSortAndSelect();

  const defaultState = (): CallEventConfirmedParams => ({
    dateMeetingStart: formatCallTime(date.value, time.value.start),
    dateMeetingEnd: formatCallTime(date.value, time.value.end),
    meetingType: undefined,
    listParticipants: [],
  });
  const confirmedState = ref<CallEventConfirmedParams>(defaultState());

  const onConfirm = () => {
    confirmedState.value.listParticipants = [...selectedList.value];
    confirmedState.value.dateMeetingStart = formatCallTime(
      date.value,
      time.value.start,
    );
    confirmedState.value.dateMeetingEnd = formatCallTime(
      date.value,
      time.value.end,
    );
  };

  const newTask = useNewTaskStore();
  const { currentData } = storeToRefs(newTask);

  const fetchLists = async () =>
    await Promise.all([fetchSuggestedUsersList(), fetchSuggestedTimesList()]);

  const init = async () => {
    await fetchLists();
    await setInitSort();
  };

  const user = useUserStore();
  const { userData } = storeToRefs(user);

  const fetchSuggestedUsersList = async () => {
    const id = userData.value?.id;
    if (!id) return;

    try {
      const rawList = (await services.calls.getSuggestedUsers(id)).data;
      const formattedList = rawList.map((u) => ({
        fullName: concatName(u.firstName, u.secondName),
        email: u.email,
      }));
      const selectedEmails = selectedList.value.map((u) => u.email);

      suggestedList.value = formattedList.filter(
        (su) => !selectedEmails.includes(su.email),
      );
    } catch (e) {
      console.error(e);
    }
  };

  const fetchSuggestedTimesList = async () => {
    const userId = userData.value?.id;
    if (!userId) return;

    const listParticipants = [...selectedList.value.map((u) => u.email)];
    const integrations = useIntegrationsStore();
    const emailCreator =
      platformView.value?.value &&
      integrations.getActive(platformView.value.value)?.accountName;
    if (emailCreator) listParticipants.push(emailCreator);

    try {
      const response = (
        await services.calls.getSuggestedTimes({
          userId,
          listParticipants,
          duration: timeRange.value,
          eventDate: format(date.value, 'dd.MM.yyyy'),
          timeZone: new Date().getTimezoneOffset() / -60,
        })
      ).data;

      timesList.value = response.map((t) => ({
        ...t,
        startTime: setCallTimeToCurrentUTC(t.startTime),
        finishTime: setCallTimeToCurrentUTC(t.finishTime),
      }));
    } catch (e) {
      console.error(e);
    }
  };

  const selectParticipant = async (p: CallParticipant) => {
    onSelect(p);
    await fetchLists();
  };

  const deselectParticipant = async (p: CallParticipant) => {
    onDeselect(p);
    await fetchLists();
  };

  async function createCall() {
    if (!userData.value || !confirmedState.value.meetingType) return;
    const integrations = useIntegrationsStore();
    const emailCreator = integrations.getActive(
      confirmedState.value.meetingType,
    )?.accountName;
    if (!emailCreator) return;

    const payload: CallEventRequestParams = {
      title: ' ',
      content: currentData.value.taskContent,
      emailCreator,
      meetingType: confirmedState.value.meetingType,
      listParticipants: [
        ...confirmedState.value.listParticipants.map((p) => p.email),
        emailCreator,
      ],
      dateMeetingStart: setCallTimeToUTC0(confirmedState.value.dateMeetingStart)
        .asString,
      dateMeetingEnd: setCallTimeToUTC0(confirmedState.value.dateMeetingEnd)
        .asString,
    };

    currentData.value.requestId = (
      await services.tasks.createCall(payload)
    ).data?.requestId;
  }

  const removeCall = async (requestId?: number) => {
    if (!requestId) return;

    const payload: CallEventRemoveParams = {
      requestId: Number(requestId),
      // meetingType: confirmedState.value.meetingType,
    };
    return services.tasks.removeCall(payload);
  };

  const getCall = async (requestId: number) => {
    try {
      rawCall.value = (await services.tasks.getCall(requestId)).data;
      await init();
      selectedList.value = rawCall.value.listParticipants.map(
        (email) =>
          list.value.find((p) => p.email === email) || {
            email: email,
            fullName: email,
          },
      );
      onSetPlatform(rawCall.value.meetingType);
      time.value.start = setCallTimeToCurrentUTC(
        rawCall.value.dateMeetingStart + 'Z',
      );
      time.value.end = setCallTimeToCurrentUTC(
        rawCall.value.dateMeetingEnd + 'Z',
      );
      date.value = new Date(rawCall.value.dateMeetingStart);
      onConfirm();
    } catch (e) {
      console.error(e);
    }
  };

  const $reset = () => {
    onReset();
    rawCall.value = undefined;
    time.value = initialTime();
    date.value = new Date();
    confirmedState.value = defaultState();
  };

  return {
    init,
    onPaste,
    onEnter,
    onConfirm,
    onSetTime,
    onSetPreset,
    onSetPlatform,
    selectParticipant,
    deselectParticipant,
    fetchSuggestedUsersList,
    fetchSuggestedTimesList,
    createCall,
    removeCall,
    getCall,
    $reset,
    rawCall,
    selectedList,
    suggestedList,
    list,
    search,
    time,
    timeRange,
    timesList,
    date,
    dateView,
    confirmedDateWithTimeView,
    platformView,
    confirmedState,
  };
});
