import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

import UiAvatar from '@/components/ui/UiAvatar.vue';
import IconClose from '@/components/icon/IconClose.vue';
import { CallParticipant } from '@/hooks/useUsersSortAndSelect';

type Props = {
  item: CallParticipant;
  removable?: boolean;
  selectable?: boolean;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'ParticipantItem',
  props: {
    item: {},
    removable: { type: Boolean },
    selectable: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "participant-item",
    style: _normalizeStyle({ cursor: _ctx.selectable ? 'pointer' : 'default' }),
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectable && _ctx.$emit('onSelect', _ctx.item)))
  }, [
    _createVNode(UiAvatar, {
      size: "1.8rem",
      fallbackName: _ctx.item.fullName,
      avatarLink: _ctx.item.avatar?.uuidLink
    }, null, 8, ["fallbackName", "avatarLink"]),
    _createTextVNode(" " + _toDisplayString(_ctx.item.fullName || _ctx.item.email) + " ", 1),
    (_ctx.removable)
      ? (_openBlock(), _createBlock(IconClose, {
          key: 0,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onDeselect', _ctx.item)))
        }))
      : _createCommentVNode("", true)
  ], 4))
}
}

})