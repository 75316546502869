import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass } from "vue"

import { CallParticipant } from '@/hooks/useUsersSortAndSelect';
import { ref } from 'vue';
import UiInput from '@/components/control/UiInput.vue';
import ParticipantItem from '@/components/popups/scheduleCall/ParticipantItem.vue';

type Props = {
  selectedList: CallParticipant[];
  search: string;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'ScheduleCallSelectedParticipantsList',
  props: {
    selectedList: {},
    search: {}
  },
  emits: ['update:search', 'onDeselect', 'onPaste', 'onEnter'],
  setup(__props: any) {




const inputRef = ref<InstanceType<typeof UiInput>>();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "selected-participants",
    onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (inputRef.value?.input?.focus()), ["stop"]))
  }, [
    _createVNode(_TransitionGroup, {
      name: "fade-group",
      appear: ""
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedList, (p) => {
          return (_openBlock(), _createElementBlock("div", {
            key: p.email
          }, [
            _createVNode(ParticipantItem, {
              item: p,
              removable: "",
              onOnDeselect: ($event: any) => (_ctx.$emit('onDeselect', p))
            }, null, 8, ["item", "onOnDeselect"])
          ]))
        }), 128))
      ]),
      _: 1
    }),
    _createVNode(UiInput, {
      placeholder: _ctx.selectedList.length ? '' : 'Start typing name or email',
      ref_key: "inputRef",
      ref: inputRef,
      small: "",
      class: _normalizeClass([
        'selected-participants__search',
        { visible: _ctx.selectedList.length },
      ]),
      modelValue: _ctx.search,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:search', $event))),
      onPaste: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('onPaste', $event)), ["stop","prevent"])),
      onKeyup: _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('onEnter', _ctx.search)), ["stop","prevent"]), ["enter"]))
    }, null, 8, ["placeholder", "class", "modelValue"])
  ]))
}
}

})