import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { IntegrationAccount, IntegrationsOptions } from '@/types/integrations';
import services from '@/api/services';
import { useZoomStore } from '@/stores/integrations/zoom';
import { MeetingType } from '@/types/calls';
import { googleSdkLoaded } from 'vue3-google-login';
import { GOOGLE_LOGIN_CONFIG } from '@/configs/google-login';
import { useGoogleAccounts } from '@/stores/integrations/google';
import { PLATFORMS } from '@/consts/calls';
import { useCallStore } from '@/stores/call';

export const useIntegrationsStore = defineStore('integrations', () => {
  const pending = ref(false);
  const list = ref<IntegrationAccount[]>([]);

  const fetch = async () => {
    try {
      pending.value = true;
      list.value = (await services.users.getIntegrations()).data;
    } catch (e) {
      console.error(e);
    } finally {
      pending.value = false;
    }
  };

  const getActive = (type: MeetingType) =>
    list.value.filter((i) => i.connection).find((i) => i.meetingType === type);

  const onZoomConnect = async () => {
    const zoom = useZoomStore();
    const link = await zoom.signup();
    window.open(link, '_blank', 'popup');
  };

  const onGoogleConnect = async () => {
    const googleAccounts = useGoogleAccounts();
    const call = useCallStore();
    googleSdkLoaded((google) =>
      google.accounts.oauth2
        .initCodeClient({
          ...GOOGLE_LOGIN_CONFIG,
          callback: async (response) => {
            if (!response.code) return;
            await googleAccounts.add({
              googleAuthorizationCode: response.code,
            });
            await Promise.all([
              googleAccounts.fetchAccountsWithCalendars(),
              fetch(),
            ]);
            if (getActive('MEET')) call.onSetPlatform('MEET');
          },
        })
        .requestCode(),
    );
  };

  const authMapping: Partial<Record<MeetingType, () => void>> = {
    ZOOM: onZoomConnect,
    MEET: onGoogleConnect,
  };

  const options = computed<IntegrationsOptions[]>(() =>
    PLATFORMS.map((p) => ({ ...p, auth: authMapping[p.value] })),
  );

  return {
    fetch,
    getActive,
    onZoomConnect,
    onGoogleConnect,
    list,
    pending,
    authMapping,
    options,
  };
});
