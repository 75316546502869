import { defineStore } from 'pinia';
import { AuthData, Credentials } from '@/types/common';
import { computed, ref, watch } from 'vue';
import services from '@/api/services';
import { ThirdPartyRequestParams, User } from '@/types/user';
import { AxiosError } from 'axios';

export const useUserStore = defineStore('user', () => {
  const authData = ref<AuthData | null>(null);
  const lsAuthData = localStorage.getItem('auth');
  const userData = ref<User | null>(null);
  const userAvatar = ref<string>('');
  if (lsAuthData) authData.value = JSON.parse(lsAuthData);

  const isLoggedIn = computed(() => !!authData.value);
  const accessToken = computed(() => authData.value?.access_token);
  const refreshToken = computed(() => authData.value?.refresh_token);

  const isProfileWidgetShow = ref(false);

  const getAvatar = async (id: number) => {
    try {
      const { data } = await services.files.getFileById(id);
      userAvatar.value = URL.createObjectURL(data);
    } catch (e) {
      console.error(e);
    }
  };

  function setAuthData(data: AuthData) {
    localStorage.setItem('auth', JSON.stringify(data));
    authData.value = data;
  }

  function removeAuthData() {
    localStorage.removeItem('auth');
    localStorage.removeItem('userId');
    authData.value = null;
  }

  async function login(credentials: Credentials) {
    try {
      const response = await services.auth.login(credentials);
      setAuthData(response.data.accessToken);
      return response;
    } catch (e: unknown) {
      removeAuthData();
      console.error(e);
      return e as AxiosError;
    }
  }

  async function thirdPartiesLogin(auth: ThirdPartyRequestParams) {
    try {
      const response = await services.auth.thirdPartiesLogin(auth);
      setAuthData(response.data.body.accessToken);
      return response;
    } catch (e: unknown) {
      removeAuthData();
      console.error(e);
    }
  }

  async function refresh() {
    return services.auth.refresh(refreshToken.value);
  }

  const getUserData = async () => {
    const { data } = await services.auth.userData();
    if (data.avatar?.id) {
      await getAvatar(data.avatar?.id);
    }
    localStorage.setItem('userId', String(data.id));
    userData.value = data;
  };

  async function remove() {
    return services.auth.deleteUser();
  }

  watch(
    () => accessToken.value,
    async (token) => token && (await getUserData()),
    { immediate: true },
  );

  return {
    authData,
    isLoggedIn,
    accessToken,
    refreshToken,
    setAuthData,
    removeAuthData,
    login,
    refresh,
    getAvatar,
    getUserData,
    userData,
    userAvatar,
    thirdPartiesLogin,
    isProfileWidgetShow,
    remove,
  };
});
