import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, Transition as _Transition, withCtx as _withCtx, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ui-drop-files__title" }
const _hoisted_2 = {
  key: 0,
  class: "ui-drop-files__preview"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "ui-drop-files__preview-document"
}
const _hoisted_6 = { class: "ui-drop-files__preview-extension" }
const _hoisted_7 = {
  key: 0,
  class: "ui-drop-files__progress"
}

import { ref } from 'vue';
import UiDropArea from '@/components/ui/UiDropArea.vue';
import IconCloud from '@/components/icon/IconCloud.vue';
import IconTrash from '@/components/icon/IconTrash.vue';
import { isImage } from '@/utils/files';
import IconCreate from '@/components/icon/IconCreate.vue';
import { useNewTaskStore } from '@/stores/newTask';
import { storeToRefs } from 'pinia';
import IconClose from '@/components/icon/IconClose.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiUploaderFiles',
  props: {
    modelValue: {}
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;

const newTask = useNewTaskStore();
const { upload } = storeToRefs(newTask);

const dropAreaRef = ref<InstanceType<typeof UiDropArea> | null>(null);

const props = __props;

const onChange = (e: Event) => {
  const files = (e.target as HTMLInputElement).files;
  if (files) {
    emit('update:modelValue', [...props.modelValue, ...Array.from(files)]);
  }
};

const handleDrop = function (e: DragEvent) {
  const dt = e.dataTransfer;
  if (!dt?.files) return;

  emit('update:modelValue', [...props.modelValue, ...Array.from(dt.files)]);
  if (dropAreaRef.value) {
    dropAreaRef.value.setInactiveDropArea();
  }
};

const removeFile = (idx: number) => {
  emit(
    'update:modelValue',
    props.modelValue.filter((_, fileIdx) => idx !== fileIdx),
  );
};

const getSrc = (file: File) => URL.createObjectURL(file);

return (_ctx: any,_cache: any) => {
  const _component_el_progress = _resolveComponent("el-progress")!

  return (_openBlock(), _createBlock(UiDropArea, {
    ref_key: "dropAreaRef",
    ref: dropAreaRef
  }, {
    default: _withCtx(({ isActiveDropArea }) => [
      _createElementVNode("div", {
        class: _normalizeClass(["ui-drop-files", { 'ui-drop-files--active': isActiveDropArea }]),
        onDrop: _withModifiers(handleDrop, ["prevent"]),
        onDragenter: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
        onDragover: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
        onDragleave: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createElementVNode("input", {
          ref: "inputFileRef",
          type: "file",
          multiple: "",
          class: "ui-drop-files__control",
          onChange: onChange
        }, null, 544),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(IconCloud, { class: "ui-drop-files__title-icon" }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('upload_drag_file')), 1)
        ]),
        (_ctx.modelValue.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (file, idx) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "ui-drop-files__preview-item",
                  key: idx,
                  onClick: ($event: any) => (removeFile(idx))
                }, [
                  (_unref(isImage)(file))
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: getSrc(file),
                        class: "ui-drop-files__preview-img",
                        alt: "img"
                      }, null, 8, _hoisted_4))
                    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(IconCreate, { color: "#778bee" }),
                          _createElementVNode("span", null, _toDisplayString(file.name.split('.').pop()), 1)
                        ]),
                        _createElementVNode("span", null, _toDisplayString(file.name), 1)
                      ])),
                  _createVNode(IconTrash, {
                    color: "#fff",
                    class: "ui-drop-files__preview-icon"
                  })
                ], 8, _hoisted_3))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _createVNode(_Transition, {
          name: "fade",
          appear: "",
          duration: 100
        }, {
          default: _withCtx(() => [
            (_unref(upload).loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("div", null, [
                    _createElementVNode("span", null, _toDisplayString(_unref(upload).progress || '0') + "%", 1),
                    _cache[3] || (_cache[3] = _createElementVNode("span", null, "Loading...", -1)),
                    _createVNode(_component_el_progress, {
                      style: {"width":"30rem"},
                      percentage: _unref(upload).progress,
                      color: "var(--color-primary)",
                      "show-text": false,
                      "stroke-width": 10
                    }, null, 8, ["percentage"])
                  ]),
                  _createVNode(IconClose, {
                    style: {"color":"var(--color-disabled)","min-width":"4rem","min-height":"4rem","padding":"1rem","cursor":"pointer"},
                    onClick: _unref(upload).abort
                  }, null, 8, ["onClick"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ], 34)
    ]),
    _: 1
  }, 512))
}
}

})