import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withKeys as _withKeys, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "ui-timepicker" }
const _hoisted_2 = { class: "ui-timepicker__list with-scroll" }
const _hoisted_3 = ["id", "onClick"]
const _hoisted_4 = ["value"]

import { computed, nextTick, ref, watch } from 'vue';
import {
  addMinutes,
  endOfDay,
  isBefore,
  isSameMinute,
  isWithinInterval,
  setHours,
  setMinutes,
  startOfDay,
  startOfMinute,
} from 'date-fns';
import format from 'date-fns/format';
import { whenever } from '@vueuse/core';

type Props = {
  modelValue?: Date;
  start?: Date | string;
  end?: Date | string;
  formatTime?: string;
  step?: number;
  hiddenValue?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'TimePicker',
  props: {
    modelValue: {},
    start: { default: '00:00' },
    end: { default: '23:59' },
    formatTime: { default: 'HH:mm' },
    step: { default: 15 },
    hiddenValue: { type: Boolean }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emits = __emit;

const isShow = ref(false);
whenever(
  () => isShow.value,
  async () =>
    await nextTick(() =>
      document
        .querySelectorAll(`#_${format(selectedDate.value, 'HH')}`)
        .forEach((el) => el.scrollIntoView({ block: 'nearest' })),
    ),
);

const divideTime = (time: Date | string) => {
  if (typeof time !== 'string') time = format(time, 'HH:mm');
  const [h, m] = (time as string).split(':');
  return { h: Number(h), m: Number(m) };
};

const selectedDate = ref<Date>(new Date());

const formatDate = (time: Date | string) => {
  const { h, m } = divideTime(time);
  if (isNaN(h) || isNaN(m)) return props.modelValue || new Date();

  return startOfMinute(setMinutes(setHours(selectedDate.value, h), m));
};

const startDate = computed(() => formatDate(props.start));
const endDate = computed(() => formatDate(props.end));

const allTimeListWithStep = computed(() => {
  const list: Date[] = [];
  const start = startOfDay(selectedDate.value);
  const end = endOfDay(selectedDate.value);
  let current = start;

  while (isBefore(current, end)) {
    list.push(current);
    current = addMinutes(current, props.step);
  }
  return list;
});
const currentTimeList = computed(() => {
  return allTimeListWithStep.value
    .filter((d) =>
      isWithinInterval(d, { start: startDate.value, end: endDate.value }),
    )
    .map((d) => ({
      raw: d,
      view: format(d, props.formatTime),
      id: format(d, '_HH'),
    }));
});

const onTimeChange = (time: Date | string) => {
  let { h, m } = divideTime(time);
  if (isNaN(h) || h > 23) h = 23;
  if (isNaN(m) || m > 59) m = 59;
  selectedDate.value = setMinutes(setHours(selectedDate.value, h), m);
  emits('update:modelValue', selectedDate.value);
};

watch(
  () => props.modelValue,
  () => props.modelValue && (selectedDate.value = props.modelValue),
  { immediate: true },
);

return (_ctx: any,_cache: any) => {
  const _component_el_popover = _resolveComponent("el-popover")!
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_popover, {
      placement: "bottom",
      width: "8rem",
      trigger: "click",
      "popper-style": "padding:0;min-width:unset",
      onBeforeEnter: _cache[3] || (_cache[3] = ($event: any) => (!currentTimeList.value.length && (isShow.value = false))),
      visible: isShow.value,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((isShow).value = $event))
    }, {
      reference: _withCtx(() => [
        _withDirectives(_createElementVNode("input", _mergeProps(_ctx.$attrs, {
          class: "ui-timepicker__reference",
          value: selectedDate.value && _unref(format)(selectedDate.value, _ctx.formatTime),
          onChange: _cache[0] || (_cache[0] = ($event: any) => (onTimeChange(($event.target as HTMLInputElement).value))),
          onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (onTimeChange(($event.target as HTMLInputElement).value)), ["enter"])),
          onBlur: _cache[2] || (_cache[2] = ($event: any) => (isShow.value = false)),
          "data-maska": "TT:TT",
          "data-maska-tokens": "{ 'T': { 'pattern': '[0-9]' } }"
        }), null, 16, _hoisted_4), [
          [_directive_maska]
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currentTimeList.value, ({ raw, view, id }) => {
            return (_openBlock(), _createElementBlock("div", {
              key: view,
              id: id,
              class: _normalizeClass({ selected: _unref(isSameMinute)(raw, selectedDate.value) }),
              onClick: ($event: any) => (onTimeChange(raw))
            }, _toDisplayString(view), 11, _hoisted_3))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}
}

})