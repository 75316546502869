import { createUrl } from '@/utils/url';

const usersUrl = createUrl('web/users');

const setFeedback = usersUrl('setFeedback');
const integrations = usersUrl('connection');
const productivity = usersUrl('external/services');

export const usersEndpoint = {
  setFeedback,
  integrations,
  productivity,
};
