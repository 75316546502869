import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, ref, watch } from 'vue';
import ModalBodyViewTask from '@/components/modals/Tasks/ModalViewTask/ModalBodyViewTask.vue';
import { useViewTaskStore } from '@/stores/viewTask/viewTask';
import { storeToRefs } from 'pinia';
import ModalFooterViewTask from './ModalFooterViewTask.vue';
import { useCoachesStore } from '@/stores/coaches/coaches';
import { CoachName } from '@/types/coaches';
import ModalHeaderViewTask from './header/ModalHeaderViewTask.vue';
import { useUserStore } from '@/stores/user';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalViewTask',
  setup(__props) {

const viewTask = useViewTaskStore();

const coachesStore = useCoachesStore();
const { setCoach } = coachesStore;
const { activeCoach } = storeToRefs(coachesStore);
const cashedCoach = ref<CoachName | null>(null);

const { state, isReadonly } = storeToRefs(viewTask);

const userStore = useUserStore();
const { userData } = storeToRefs(userStore);

const isClosable = computed(() => !!userData.value || !state.value.isShared);
watch(
  () => state.value.isOpenModal,
  (value) => {
    if (value) {
      cashedCoach.value = activeCoach.value.name;
      setCoach(state.value.isShared ? 'sharedTask' : 'viewTask');
    } else {
      cashedCoach.value && setCoach(cashedCoach.value);
    }
  },
);

return (_ctx: any,_cache: any) => {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    "model-value": _unref(state).isOpenModal,
    class: _normalizeClass([
      `modal-view modal`,
      _unref(state).isShared && 'modal__short',
      _unref(state).isShowComments && 'modal__full',
    ]),
    "modal-class": ['el-overlay', !isClosable.value && 'short'].join(' '),
    "close-on-click-modal": isClosable.value,
    "close-on-press-escape": isClosable.value,
    modal: isClosable.value,
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_unref(state).isOpenModal && (_unref(viewTask).upload.abort(), _unref(viewTask).clear())))
  }, {
    header: _withCtx(() => [
      _createVNode(ModalHeaderViewTask)
    ]),
    default: _withCtx(() => [
      _createVNode(ModalBodyViewTask)
    ]),
    footer: _withCtx(() => [
      _createVNode(ModalFooterViewTask, { "is-readonly": _unref(isReadonly) }, null, 8, ["is-readonly"])
    ]),
    _: 1
  }, 8, ["model-value", "class", "modal-class", "close-on-click-modal", "close-on-press-escape", "modal"]))
}
}

})