import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "feedback-modal__header" }
const _hoisted_2 = { class: "feedback-modal__title" }
const _hoisted_3 = { class: "feedback-modal__description" }
const _hoisted_4 = { class: "feedback-modal__content" }
const _hoisted_5 = { class: "feedback-modal__btns" }

import { ref, computed } from 'vue';
import UiUploaderFiles from '@/components/ui/UiUploaderFiles.vue';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import services from '@/api/services';
import { UserTypeFeedback } from '@/types/user';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { filesTypes } from '@/consts';
import { createAvatarLink } from '@/utils/url';
import ModalNotification from '@/components/modals/ModalNotification.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalFeedback',
  props: {
    isOpen: { type: Boolean }
  },
  emits: ['onClose', 'openContact'],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();
const { userData } = storeToRefs(useUserStore());


const emit = __emit;

const isLoading = ref(false);

const selectList = computed(() => {
  return [
    {
      value: 'Speech recognition',
      label: 'Speech recognition',
    },
    {
      value: 'Auto-translation',
      label: 'Auto-translation',
    },
    {
      value: 'Body Task',
      label: 'Body Task',
    },
    {
      value: 'Task table',
      label: 'Task table',
    },
    {
      value: 'Dashboard',
      label: 'Dashboard',
    },
    {
      value: 'Analytics tab',
      label: 'Analytics tab',
    },
    {
      value: 'Billing tab',
      label: 'Billing tab',
    },
    {
      value: 'Sharing tasks',
      label: 'Sharing tasks',
    },
    {
      value: 'Calendar',
      label: 'Calendar',
    },
    {
      value: 'Filters',
      label: 'Filters',
    },
    {
      value: 'Chat',
      label: 'Chat',
    },
    {
      value: 'Workload',
      label: 'Workload',
    },
    {
      value: 'Notifications',
      label: 'Notifications',
    },
    {
      value: 'Forms',
      label: 'Forms',
    },
  ];
});

const form = ref({
  category: selectList.value[0].value,
  files: [],
  description: '',
  errorSubject: false,
  errorDetail: false,
});

const sendFeedback = async () => {
  if (!form.value.description) {
    form.value.errorDetail = true;
    return;
  }
  isLoading.value = true;
  const { description, files, category } = form.value;
  let body = {
    description,
    email: userData.value?.email || '',
    subject: category,
  };

  try {
    if (files.length) {
      const data = await createMediaFile(files);
      body.description += ` ${data.join(', ')}`;
    }
    await services.users.setFeedback(body, UserTypeFeedback.FEEDBACK);
    form.value.files = [];
    form.value.description = '';
    form.value.category = selectList.value[0].value;
    ElNotification.success(t('email_sent'));
    isLoading.value = false;
    emit('onClose');
  } catch (e) {
    ElNotification.error(t('some_error'));
    isLoading.value = false;
  }
};

const createMediaFile = async (files: File[]) => {
  const filesLink: string[] = [];
  const filesTypesMapping = {
    application: filesTypes['ATTACHMENT'],
    audio: filesTypes['AUDIO'],
    image: filesTypes['PHOTO'],
  } as const;

  const groupedFiles = files.reduce(
    (groupedFiles: Record<string, File[]>, file) => {
      const type =
        filesTypesMapping[
          file.type.split('/')[0] as keyof typeof filesTypesMapping
        ] || 'ATTACHMENT';
      if (!groupedFiles[type]) groupedFiles[type] = [];
      groupedFiles[type].push(file);
      return groupedFiles;
    },
    {},
  );

  for await (const group of Object.keys(groupedFiles)) {
    const formData = new FormData();
    formData.append('fileType', group);

    groupedFiles[group].forEach((file) => {
      formData.append('files', file);
    });

    try {
      const resFiles = await services.files.add(formData);
      resFiles.data.forEach((file) => {
        filesLink.push(createAvatarLink(file.uuidLink) || '');
      });
    } catch (e) {
      ElNotification.error({
        message: t('some_error'),
        offset: 80,
      });
      console.error(e);
    }
  }

  return filesLink;
};

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_ui_input = _resolveComponent("ui-input")!
  const _component_ui_button = _resolveComponent("ui-button")!

  return (_openBlock(), _createBlock(ModalNotification, _mergeProps({
    class: "feedback-modal notification-modal",
    "model-value": _ctx.isOpen
  }, _ctx.$attrs, {
    onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('onClose'))),
    width: "100%",
    "append-to-body": ""
  }), {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('support_modals.feedback.title')), 1),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('support_modals.feedback.description')), 1)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_el_select, {
          modelValue: form.value.category,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.category) = $event)),
          "value-key": "name",
          class: "feedback-modal__select error",
          placeholder: _ctx.$t('support_modals.feedback.form.category')
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectList.value, (item, i) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: i,
                label: item.label,
                value: item.value
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue", "placeholder"]),
        _createVNode(_component_ui_input, {
          class: _normalizeClass(["feedback-modal__textarea", { error: form.value.errorDetail }]),
          type: "textarea",
          rows: "7",
          modelValue: form.value.description,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.description) = $event)),
          label: _ctx.$t('support_modals.feedback.form.subject'),
          onInput: _cache[2] || (_cache[2] = ($event: any) => (form.value.errorDetail = false))
        }, null, 8, ["modelValue", "label", "class"]),
        _createVNode(UiUploaderFiles, {
          modelValue: form.value.files,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.value.files) = $event))
        }, null, 8, ["modelValue"])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_ui_button, {
          onClick: sendFeedback,
          "is-disabled": isLoading.value,
          "is-loading": isLoading.value
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('send')), 1)
          ]),
          _: 1
        }, 8, ["is-disabled", "is-loading"]),
        _createVNode(_component_ui_button, {
          plain: "",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('openContact')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('support_modals.feedback.problem')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 16, ["model-value"]))
}
}

})