import { ApiService } from '@/api/services/core';
import { axiosInstance } from '@/api/axios';
import { ENDPOINTS } from '@/api/endpoints';
import { IFile, ITableSorts } from '@/types/common';
import { Pageable } from '@/types/pagination';
import {
  FileItem,
  UserFilesFilters,
  WorkspaceFilesFilters,
} from '@/types/files';
import { AxiosProgressEvent, AxiosRequestConfig } from 'axios';

export class FilesService extends ApiService {
  getUsersFiles(params: ITableSorts<FileItem> & UserFilesFilters) {
    return axiosInstance.get<Pageable<FileItem>>(ENDPOINTS.files.getUserList, {
      params,
    });
  }

  getWorkspaceFiles(params: ITableSorts<FileItem> & WorkspaceFilesFilters) {
    return axiosInstance.get<Pageable<FileItem>>(
      ENDPOINTS.files.getWorkspaceList,
      { params },
    );
  }

  add(
    formData: FormData,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
    signal?: AbortSignal,
  ) {
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    if (onUploadProgress) config.onUploadProgress = onUploadProgress;
    if (signal) config.signal = signal;

    return axiosInstance.post<IFile[]>(ENDPOINTS.files.files, formData, config);
  }

  getFileById(id: number) {
    return axiosInstance.get<Blob>(ENDPOINTS.files.files, {
      params: {
        fileId: id,
      },
      responseType: 'blob',
    });
  }

  deleteFileById(id: number) {
    return axiosInstance.delete(ENDPOINTS.files.files, {
      params: {
        fileId: id,
      },
    });
  }
}
