import { ref, shallowRef, watch } from 'vue';
import { useWorkspaceStore } from '@/stores/workspaces';
import { storeToRefs } from 'pinia';
import { KeysMatching } from '@/types/utils';
import { isEmailValid } from '@/utils/text';
import { SubordinateViewType } from '@/utils/subordinates';
import { useSubordinatesStore } from '@/stores/subordinates/subordinates';
import _ from 'lodash';

export type CallParticipant = {
  fullName: string;
  email: string;
  avatar?: SubordinateViewType['avatar'];
};

export const useUsersSortAndSelect = () => {
  const list = ref<CallParticipant[]>([]);
  const originalList = shallowRef<CallParticipant[]>([]);
  const selectedList = ref<CallParticipant[]>([]);
  const suggestedList = ref<CallParticipant[]>([]);
  const search = ref('');

  watch(
    () => search.value,
    (s) => {
      if (!s) return;

      list.value.sort((a, b) => {
        const compareFields = (
          fields: KeysMatching<CallParticipant, string>[],
        ) => {
          const search = s.toLowerCase();
          let direction = 0;

          fields.forEach((field) => {
            const A = a[field].toLowerCase();
            const B = b[field].toLowerCase();
            if (A.includes(search) && !B.includes(search)) direction = -1;
          });
          return direction;
        };

        return compareFields(['fullName', 'email']);
      });
    },
  );

  const subordinates = useSubordinatesStore();
  const { subordinatesOverallListView } = storeToRefs(subordinates);

  const workspacesStore = useWorkspaceStore();
  const { users } = storeToRefs(workspacesStore);

  const setInitSort = async () => {
    if (!subordinatesOverallListView.value.length)
      await subordinates.fetchOverallList();

    list.value = _.cloneDeep(subordinatesOverallListView.value) || [];
    const currentWorkspaceUsersIds =
      subordinatesOverallListView.value?.map((u) => u.id) || [];
    const otherUsers =
      users.value?.filter((u) => !currentWorkspaceUsersIds.includes(u.id)) ||
      [];
    list.value.push(...otherUsers);
    originalList.value = [...list.value];

    const selectedEmails = selectedList.value.map((s) => s.email);
    list.value = list.value.filter((u) => !selectedEmails.includes(u.email));
  };

  const resetSearch = () => (search.value = '');

  const onSelect = (item: CallParticipant) => {
    const id = list.value.findIndex((u) => u.email === item.email);
    if (id > -1) list.value.splice(id, 1);
    selectedList.value.push(item);
  };

  const onDeselect = (item: CallParticipant) => {
    const id = selectedList.value.findIndex((u) => u.email === item.email);
    if (id === -1) return;

    selectedList.value.splice(id, 1);
    if (originalList.value.some((u) => u.email === item.email))
      list.value.push(item);
  };

  const onAdd = (text?: string) => {
    if (!text || selectedList.value.some((u) => u.email === text)) return;

    const userInList = list.value.find((u) => u.email === text);
    if (userInList) {
      resetSearch();
      return onSelect(userInList);
    }

    if (text && isEmailValid(text)) {
      resetSearch();
      return onSelect({ fullName: text, email: text });
    }

    search.value = text;
  };

  const onPaste = (cb: ClipboardEvent) => {
    const text = cb.clipboardData?.getData('text');
    text && onAdd(text.trim());
  };

  const onEnter = (text?: string) => {
    text && onAdd(text.trim());
  };

  const onReset = () => {
    selectedList.value = [];
    list.value = [...originalList.value];
    resetSearch();
  };

  return {
    list,
    selectedList,
    originalList,
    suggestedList,
    search,
    onSelect,
    onDeselect,
    onPaste,
    onEnter,
    onReset,
    resetSearch,
    setInitSort,
  };
};
