<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icons/time">
      <circle
        id="Ellipse 13"
        cx="10"
        cy="10"
        r="8.25"
        stroke="currentColor"
        stroke-width="1.5"
      />
      <path
        id="Vector 8"
        d="M10 6.25V10.6016C10 10.8495 10.0921 11.0887 10.2585 11.2725L12.5 13.75"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </g>
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped></style>
