<script setup lang="ts">
import { POPPER_MODIFIERS_DEFAULT } from '@/consts/agenda/popperModifiers';
import IconChevron from '@/components/icon/IconChevron.vue';
import { computed, onMounted, ref, watch } from 'vue';
import ScheduleCallSelectedParticipantsList from '@/components/popups/scheduleCall/ScheduleCallSelectedParticipantsList.vue';
import ScheduleCallParticipantsList from '@/components/popups/scheduleCall/ScheduleCallParticipantsList.vue';
import { useNewTaskStore } from '@/stores/newTask';
import { storeToRefs } from 'pinia';
import UiAvatar from '@/components/ui/UiAvatar.vue';
import IconUsersColored from '@/components/icon/IconUsersColored.vue';
import IconSmart from '@/components/icon/IconSmart.vue';
import ParticipantItem from '@/components/popups/scheduleCall/ParticipantItem.vue';
import { useCallStore } from '@/stores/call';
import IconTime from '@/components/icon/IconTime.vue';
import TimePresets from '@/components/time/TimePresets.vue';
import TimeSelectRange from '@/components/time/TimeSelectRange.vue';
import ScheduleCallAITimes from '@/components/popups/scheduleCall/ScheduleCallAITimes.vue';
import { DatePicker as VDatePicker } from 'v-calendar';
import { MeetingType } from '@/types/calls';
import IconCopy from '@/components/icon/IconCopy.vue';
import { copyToClipboard } from '@/utils/copyToClipboard';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { useIntegrationsStore } from '@/stores/integrations/integrations';

type Props = { isOpen?: boolean; readOnly?: boolean };
const props = defineProps<Props>();

const { t } = useI18n();

const isPlatformsListVisible = ref(false);
const isParticipantsListVisible = ref(false);

const newTask = useNewTaskStore();
const { isSubordinatesFetching } = storeToRefs(newTask);

const call = useCallStore();
const {
  selectedList,
  confirmedState,
  suggestedList,
  list,
  search,
  platformView,
  rawCall,
} = storeToRefs(call);

const onSaveClick = () => {
  isParticipantsListVisible.value = false;
  call.onConfirm();
};

const onCancelClick = () => (isParticipantsListVisible.value = false);

const slicedConfirmedList = computed(() => {
  const visible = confirmedState.value.listParticipants?.slice(0, 3);
  const tailCount = (confirmedState.value.listParticipants?.length || 0) - 3;
  return { visible, tailCount: tailCount > 0 ? `+${tailCount}` : 0 };
});

const integrations = useIntegrationsStore();
const { list: integrationsList } = storeToRefs(integrations);

const onSelectPlatform = (value: MeetingType) => {
  call.onSetPlatform(value);
  isPlatformsListVisible.value = false;
};

const onCopyLink = () => {
  const link = rawCall.value?.linkToMeet;
  if (!link) return;
  copyToClipboard(link);
  ElNotification.success({
    message: t('task_sharing.link_copied'),
    offset: 80,
  });
};

onMounted(() => integrations.fetch());
watch(
  () => [integrationsList.value, props.isOpen],
  () => {
    const type = integrationsList.value.find(
      (item) => item.connection,
    )?.meetingType;
    call.onSetPlatform(type);
  },
  { immediate: true, deep: true },
);
</script>

<template>
  <div
    :class="[
      'schedule-call',
      { open: isOpen },
      { disabled: isSubordinatesFetching },
    ]"
  >
    <div
      class="layout"
      v-if="isParticipantsListVisible"
      @click="isParticipantsListVisible = false"
    />
    <div class="schedule-call__toggle" @click="$emit('update:isOpen', !isOpen)">
      <img src="@images/calls/schedule-call.svg" alt="" />
      <span>Schedule a call</span>
      <el-switch class="green" :modelValue="isOpen" />
    </div>
    <div class="schedule-call__content">
      <el-popover
        width="850"
        :popper-options="POPPER_MODIFIERS_DEFAULT"
        :visible="isParticipantsListVisible"
        popper-style="transition: all 0.15s ease-in-out"
      >
        <div class="schedule-call__participants">
          <ScheduleCallSelectedParticipantsList
            class="schedule-call__participants-selected"
            :selectedList="selectedList"
            v-model:search="search"
            @onDeselect="call.deselectParticipant"
            @onPaste="call.onPaste"
            @onEnter="call.onEnter"
          />
          <div class="schedule-call__column with-scroll">
            <div class="schedule-call__column-head">
              <IconUsersColored />Users
            </div>
            <ScheduleCallParticipantsList
              :list="list"
              @onSelect="call.selectParticipant"
            />
          </div>
          <div class="schedule-call__column with-scroll">
            <div class="schedule-call__column-head">
              <IconSmart />Suggestions
            </div>
            <div class="schedule-call__participants-suggested">
              <template v-if="suggestedList.length">
                <TransitionGroup name="fade-group" appear>
                  <div v-for="p in suggestedList" :key="p.email">
                    <ParticipantItem
                      :item="p"
                      selectable
                      @onSelect="call.selectParticipant"
                    />
                  </div>
                </TransitionGroup>
              </template>
              <span v-else>No suggestions</span>
            </div>
          </div>
          <div class="schedule-call__column with-scroll">
            <div class="schedule-call__column-head"><IconTime />Date&Time</div>
            <div class="schedule-call__date">
              Date
              <v-date-picker v-model="call.date" :locale="$i18n.locale">
                <template #default="{ togglePopover }">
                  <button @click="togglePopover">{{ call.dateView }}</button>
                </template>
              </v-date-picker>
            </div>
            <TimePresets
              @setPreset="
                call.onSetPreset($event);
                call.fetchSuggestedTimesList();
              "
              :start="call.time.start"
              :end="call.time.end"
            />
            <TimeSelectRange
              v-model:startTime="call.time.start"
              v-model:endTime="call.time.end"
            />
            <ScheduleCallAITimes
              :times="call.timesList"
              :currentTime="call.time"
              :currentDate="call.date"
              @onTimeChange="call.onSetTime"
            />
          </div>
        </div>
        <div class="schedule-call__participants-control">
          <ui-button plain @click="onCancelClick">{{ $t('cancel') }}</ui-button>
          <ui-button @click="onSaveClick">{{ $t('save') }}</ui-button>
        </div>
        <template #reference>
          <div
            class="schedule-call__participants-current"
            @click="
              !newTask.currentData.requestId &&
                !readOnly &&
                (isParticipantsListVisible = true)
            "
          >
            <span>Participants</span>
            <div>
              <TransitionGroup name="fade-group" appear>
                <div v-for="p in slicedConfirmedList.visible" :key="p.email">
                  <el-tooltip
                    :content="p.email"
                    effect="customized"
                    placement="bottom"
                  >
                    <UiAvatar
                      :avatarLink="p.avatar?.uuidLink"
                      :fallbackName="p.fullName"
                      size="2.6rem"
                    />
                  </el-tooltip>
                </div>
                <div class="counter" v-if="slicedConfirmedList.tailCount">
                  {{ slicedConfirmedList.tailCount }}
                </div>
              </TransitionGroup>
              <img
                src="@images/calls/participant.svg"
                alt=""
                v-if="!slicedConfirmedList.visible.length"
              />
            </div>
            <div>{{ call.confirmedDateWithTimeView }}</div>
          </div>
        </template>
      </el-popover>
      <el-popover
        trigger="click"
        width="200"
        popper-style="padding: 0"
        :popper-options="POPPER_MODIFIERS_DEFAULT"
        v-model:visible="isPlatformsListVisible"
      >
        <div class="schedule-call__platform">
          <div
            v-for="{
              value,
              label,
              icon,
              authLabel,
              auth,
            } in integrations.options"
            :key="label"
            @click="
              integrations.getActive(value) ? onSelectPlatform(value) : auth()
            "
          >
            <img :src="icon" alt="" />
            {{ integrations.getActive(value) ? label : authLabel }}
          </div>
        </div>
        <template #reference>
          <div class="schedule-call__platform-current" v-if="!readOnly">
            <img :src="platformView?.icon" alt="" />
            {{ platformView?.label || 'Choose platform' }}
            <IconChevron
              :direction="isPlatformsListVisible ? 'top' : 'bottom'"
            />
          </div>
        </template>
      </el-popover>
      <div
        v-if="readOnly"
        class="schedule-call__platform-view"
        @click="onCopyLink"
      >
        <img :src="platformView?.icon" alt="" />
        {{ platformView?.label || 'Undefined' }}
        <IconCopy />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.schedule-call {
  display: grid;
  grid-template-rows: 3.2rem 0fr;
  grid-template-columns: 1fr;
  transition: all 0.15s ease-in-out;
  @include f14;

  &.open {
    grid-template-rows: 3.2rem 1fr;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &__toggle {
    @include flex-row(1rem);
    align-items: center;
    justify-content: space-between;
    user-select: none;
    cursor: pointer;

    & > *:last-child {
      margin-left: auto;
    }
  }

  &__date {
    @include flex-row(1rem);
    justify-content: space-between;
  }

  &__participants {
    display: grid;
    max-height: 42rem;
    grid-template-rows: auto 1fr;
    grid-template-columns: 28rem auto 29rem;
    gap: 1rem;

    &-selected {
      grid-column: span 3;
    }

    &-suggested {
      @include flex-row;
      gap: 1rem;
      flex-wrap: wrap;

      & > div {
        width: fit-content;
      }
    }

    &-current {
      @include flex-row(1rem);
      align-items: center;
      padding: 0.6rem 0.8rem;
      @include gray-bg-hover;
      cursor: pointer;
      justify-self: start;
      overflow: hidden;
      width: 100%;

      & > div {
        @include flex-row;
        align-items: center;

        &:last-child {
          margin-left: auto;
        }

        & > .counter {
          position: relative;
          display: grid;
          place-content: center;
          width: 2.6rem;
          height: 2.6rem;
          border-radius: 50%;
          background: var(--color-primary);
          color: #fff;
        }

        & > img {
          position: relative;
        }

        & > *:not(:first-child) {
          margin: -0.3rem;
        }
      }
    }

    &-control {
      @include flex-row(1rem);
      align-items: center;
      justify-content: flex-end;
      margin-top: 1rem;
    }
  }

  &__column {
    @include flex-column(1rem);
    overflow-y: auto;
    overflow-x: hidden;

    &-head {
      @include flex-row(1rem);
      align-items: center;
      background: #fcfcfc;
      position: sticky;
      top: 0;
      z-index: 5;
      padding: 1rem 0;

      & > svg {
        color: var(--color-primary);
      }
    }
  }

  &__content {
    @include flex-row(1rem);
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
  }

  &__platform {
    @include flex-column;
    padding: 0.8rem;
    overflow: hidden;

    & > div {
      @include flex-row(1rem);
      align-items: center;
      cursor: pointer;
      padding: 1rem;
      @include gray-bg-hover;
      font-size: 1.3rem;
    }

    &-current {
      @include flex-row(1rem);
      align-items: center;
      padding: 0.8rem 1rem;
      border-radius: 0.8rem;
      border: 0.1rem solid #a3c0f9;
      cursor: pointer;
      width: 19rem;
      flex-shrink: 0;

      & > *:last-child {
        margin-left: auto;
      }
    }

    &-view {
      @include flex-row(1rem);
      align-items: center;
      flex: 1 0 auto;
      cursor: pointer;
    }

    &,
    &-current,
    &-view {
      & img {
        height: 2.4rem;
      }
    }
  }
}
</style>
