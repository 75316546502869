import { createUrl } from '@/utils/url';

const suggested =
  process.env.VUE_APP_AI_URL + 'api/v1/openAi/choose/event/participant';

const time = process.env.VUE_APP_AI_URL + 'api/v1/openAi/choose/event/time';

const tasksUrl = createUrl('web/task');
const create = tasksUrl('create/meeting');
const remove = tasksUrl('remove/meeting');
const get = tasksUrl('event/meeting');

export const callsEndpoint = { suggested, time, create, remove, get };
