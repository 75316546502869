import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }

import { useViewTaskStore } from '@/stores/viewTask/viewTask';
import HeaderActions from './HeaderActions.vue';
import HeaderInfo from './HeaderInfo.vue';
import { storeToRefs } from 'pinia';
import HeaderWorkspaceSelect from './HeaderWorkspaceSelect.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalHeaderViewTask',
  setup(__props) {

const viewTask = useViewTaskStore();
const { activeTask, isReadonly, state } = storeToRefs(viewTask);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(state).isShared)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Shared task"))
      : (_openBlock(), _createBlock(HeaderInfo, {
          key: 1,
          "is-loading": _unref(state).isTaskLoading,
          "is-readonly": _unref(isReadonly),
          number: _unref(activeTask).itemNumber,
          status: _unref(activeTask).taskStatus
        }, {
          default: _withCtx(() => [
            _createVNode(HeaderWorkspaceSelect, {
              "company-id": _unref(activeTask).companyId,
              onSelect: _cache[0] || (_cache[0] = ($event: any) => (_unref(activeTask).companyId = $event))
            }, null, 8, ["company-id"])
          ]),
          _: 1
        }, 8, ["is-loading", "is-readonly", "number", "status"])),
    _createVNode(HeaderActions, { readOnly: _unref(isReadonly) }, null, 8, ["readOnly"])
  ], 64))
}
}

})