import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal-create-footer footer" }
const _hoisted_2 = { class: "footer__lang" }
const _hoisted_3 = { class: "footer__record" }
const _hoisted_4 = { class: "footer__control" }

import { useNewTaskStore } from '@/stores/newTask';
import { storeToRefs } from 'pinia';
import { computed, toRefs, watch } from 'vue';
import LangRecognitionSelector from '@/components/modals/Tasks/ModalCreateTask/LangRecognitionSelector.vue';
import IconCheck from '@/components/icon/IconCheck.vue';
import UiAudioRecorder from '@/components/ui/UiAudioRecorder.vue';
import { sockets } from '@/api/services';
import { useCallStore } from '@/stores/call';

type Props = {
  isDisabledSubmit: boolean;
  isOpenModal: boolean;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'ModalCreateTaskFooter',
  props: {
    isDisabledSubmit: { type: Boolean },
    isOpenModal: { type: Boolean }
  },
  emits: ['submit'],
  setup(__props: any) {




const newTask = useNewTaskStore();
const { hasScheduledCall } = storeToRefs(newTask);
const { parseDescription } = newTask;

const call = useCallStore();
const { confirmedState } = storeToRefs(call);

const onStartRecord = async () => {
  sockets.speech.emitter
    .subscribe('AddPartialTranscript', (speech: string) => {
      newTask.audio.runtimeSpeech = speech;
    })
    .subscribe('AddTranscript', (speech: string) => {
      newTask.audio.runtimeSpeech = '';
      newTask.currentData.taskContent += speech;
    })
    .subscribe('onClose', () => (newTask.audio.runtimeSpeech = ''));
};

const onStopRecord = async () => {
  sockets.speech.emitter.reset();
  await parseDescription();
};

const isCreateBtnDisabled = computed(() => {
  const { taskPriority, taskContent, executor, name, deadline } =
    currentData.value;

  const callConditions =
    hasScheduledCall.value &&
    confirmedState.value.listParticipants.length &&
    confirmedState.value.meetingType &&
    confirmedState.value.dateMeetingStart &&
    confirmedState.value.dateMeetingEnd &&
    currentData.value.taskContent;

  const taskConditions =
    !hasScheduledCall.value &&
    deadline &&
    taskPriority.value &&
    taskContent &&
    taskContent.length <= 10_000 &&
    executor?.id &&
    name &&
    name.length <= 75;

  return !(callConditions || taskConditions) || isRecording.value;
});

const { currentData, audio } = storeToRefs(newTask);
const { isRecording, recordFile } = toRefs(audio.value);

watch(recordFile, (val) => (currentData.value.audioFile = val));

return (_ctx: any,_cache: any) => {
  const _component_ui_button = _resolveComponent("ui-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(LangRecognitionSelector)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(UiAudioRecorder, {
        id: "task-record",
        audio: _unref(audio),
        onOnStopRecord: onStopRecord,
        onOnStartRecord: onStartRecord
      }, null, 8, ["audio"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_ui_button, {
        isDisabled: isCreateBtnDisabled.value,
        isLoading: _ctx.isDisabledSubmit,
        class: "footer__control-submit",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('submit')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('modal_task.create_task')) + " ", 1),
          _createVNode(IconCheck, { color: "#ffffff" })
        ]),
        _: 1
      }, 8, ["isDisabled", "isLoading"])
    ])
  ]))
}
}

})