import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, mergeProps as _mergeProps, isRef as _isRef, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  class: "modal-create__form-row",
  id: "task-assigment"
}
const _hoisted_3 = { class: "modal-create__form-col modal-create__form-autocomplete" }
const _hoisted_4 = { class: "modal-create__form-col" }
const _hoisted_5 = { class: "modal-create__form-row" }

import { ref, watch, toRef, computed } from 'vue';

import UiModal from '@/components/ui/UiModal.vue';
import UiAutocompleteUsers from '@/components/ui/UiAutocomplete/UiAutocompleteUsers.vue';
import UiDropdownPriority from '@/components/ui/UiDropdown/UiDropdownPriority.vue';
import UiUploaderFiles from '@/components/ui/UiUploaderFiles.vue';

import { useNewTaskStore } from '@/stores/newTask';
import ModalCreateTaskFooter from './ModalCreateTaskFooter.vue';
import { storeToRefs } from 'pinia';
import { SubordinateViewType } from '@/utils/subordinates';
import { priorityOptions } from '@/consts';
import { useTasksStore } from '@/stores/tasks/tasks';
import { CoachName } from '@/types/coaches';
import { useCoachesStore } from '@/stores/coaches/coaches';
import WidgetCoach from '@/components/widget/WidgetCoach.vue';
import PopupTaskDatePicker from '@/components/popups/PopupTaskDatePicker.vue';
import { useWorkspaceStore } from '@/stores/workspaces';
import IconTrash from '@/components/icon/IconTrash.vue';
import UiInput from '@/components/control/UiInput.vue';
import IconSmart from '@/components/icon/IconSmart.vue';
import ModalNotification from '@/components/modals/ModalNotification.vue';
import { watchPausable, whenever } from '@vueuse/core';
import PopupTaskScheduleCall from '@/components/popups/scheduleCall/PopupTaskScheduleCall.vue';
import { useUserStore } from '@/stores/user';
import { onBeforeMountAndWorkspaceChange } from '@/hooks/onBeforeMountAndWorkspaceChange';
import HeaderWorkspaceSelect from '@/components/modals/Tasks/ModalViewTask/header/HeaderWorkspaceSelect.vue';
import IconFavorite from '@/components/icon/IconFavorite.vue';
import { useCallStore } from '@/stores/call';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalCreateTask',
  props: {
    isOpen: { type: Boolean, default: false },
    modal: { type: Boolean, default: true }
  },
  emits: ['onClose'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const coachesStore = useCoachesStore();
const { setCoach } = coachesStore;
const { activeCoach } = storeToRefs(coachesStore);

const userStore = useUserStore();
const { userData } = storeToRefs(userStore);

const call = useCallStore();

const newTask = useNewTaskStore();
const {
  hasSmartResponse,
  dirtyState,
  pendingSmartResponse,
  audio,
  isChanged,
  currentData,
  subordinates,
  isSubordinatesFetching,
  datePickerData,
  hasScheduledCall,
  upload,
} = storeToRefs(newTask);
whenever(() => hasScheduledCall.value, call.init);

const tasksStore = useTasksStore();

const workspacesStore = useWorkspaceStore();
const { currentWorkspace } = storeToRefs(workspacesStore);

const isOpen = toRef(props, 'isOpen');
const cashedCoach = ref<CoachName | null>(null);
const searchUsers = ref('');
const isDisabledSubmit = ref(false);
const isOpenAutoComplete = ref(false);

const isDisabledField = computed(
  () => !hasSmartResponse.value && !currentData.value.taskContent.length,
);

const isDisabledSmartPlanning = computed(
  () =>
    hasSmartResponse.value ||
    !currentData.value.taskContent.length ||
    audio.value.isRecording ||
    pendingSmartResponse.value,
);

const isConfirmClosingOpen = ref(false);

const handleCloseConfirm = () => {
  if (!isChanged.value) {
    handleClose(true);
    return;
  }
  isConfirmClosingOpen.value = true;
};

const handleClose = (value: boolean) => {
  isConfirmClosingOpen.value = false;
  if (currentData.value.requestId) call.removeCall(currentData.value.requestId);
  upload.value.abort();
  emit('onClose', value);
};

const selectUser = (user: SubordinateViewType) => {
  currentData.value.executor = user;
  isOpenAutoComplete.value = false;
};
const hideAutocomplete = () => {
  isOpenAutoComplete.value = false;
};

const createTask = async () => {
  if (isDisabledSubmit.value) return;
  isDisabledSubmit.value = true;
  await newTask.createTask();
  emit('onClose');
  isDisabledSubmit.value = false;

  await tasksStore.getTodoCount();
};

const onResetTask = () => {
  if (currentData.value.requestId) call.removeCall(currentData.value.requestId);
  newTask.resetTask();
};

const taskContentValue = computed(() =>
  audio.value.isRecording
    ? `${currentData.value.taskContent} ${
        audio.value.runtimeSpeech || ''
      }`.trim()
    : currentData.value.taskContent,
);

const { pause, resume } = watchPausable(
  () => [currentWorkspace.value?.id, currentData?.value?.companyId],
  () => !isSubordinatesFetching.value && newTask.getSubordinates(),
);
pause();

const contentInput = ref<InstanceType<typeof UiInput> | null>(null);

watch(
  () => contentInput.value?.input,
  () => contentInput.value?.input?.focus(),
);

watch(
  () => isOpen.value,
  (value) => {
    if (value) {
      newTask.getSubordinates().then(() => {
        newTask.initStateChanged();
        resume();
      });
      cashedCoach.value = activeCoach.value.name;
      setCoach('createTask');
    } else {
      pause();
      newTask.resetStateChanged();
      cashedCoach.value && setCoach(cashedCoach.value);
    }
  },
);

onBeforeMountAndWorkspaceChange(async () => {
  userData.value?.currentCompany &&
    (currentData.value.companyId = userData.value?.currentCompany);
});

return (_ctx: any,_cache: any) => {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createBlock(UiModal, {
    "is-open": isOpen.value,
    onOnCloseClick: handleCloseConfirm,
    title: _ctx.$t('create_modal_task.title'),
    class: "modal-create",
    "close-on-click-modal": false,
    "close-on-press-escape": false,
    modal: _ctx.modal,
    "close-disabled": !_unref(audio).isRecording
  }, {
    headerTitle: _withCtx(() => [
      _renderSlot(_ctx.$slots, "headerTitle", {}, () => [
        _createVNode(HeaderWorkspaceSelect, {
          "company-id": _unref(currentData).companyId,
          onSelect: _cache[0] || (_cache[0] = ($event: any) => (_unref(currentData).companyId = $event))
        }, null, 8, ["company-id"])
      ])
    ]),
    headerActions: _withCtx(() => [
      _createVNode(_component_el_tooltip, {
        content: "Add task to favourites",
        placement: "bottom",
        disabled: _unref(currentData).isMarked,
        effect: "customized"
      }, {
        default: _withCtx(() => [
          _createVNode(IconFavorite, {
            class: "modal-create__favorite",
            isActive: _unref(currentData).isMarked,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(currentData).isMarked = !_unref(currentData).isMarked))
          }, null, 8, ["isActive"])
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createElementVNode("div", {
        class: _normalizeClass([
          'modal-create__smart',
          { disabled: isDisabledSmartPlanning.value || _unref(hasScheduledCall) },
        ]),
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_unref(newTask).parseDescription && _unref(newTask).parseDescription(...args)))
      }, [
        _createTextVNode(_toDisplayString(_ctx.$t('create_modal_task.smart_planning')), 1),
        _createVNode(IconSmart)
      ], 2),
      _createVNode(_component_el_tooltip, {
        content: _ctx.$t('clear_all'),
        effect: "customized",
        placement: "top"
      }, {
        default: _withCtx(() => [
          _createElementVNode("button", {
            class: "modal-create__reset",
            onClick: onResetTask,
            disabled: _unref(audio).isRecording
          }, [
            _createVNode(IconTrash)
          ], 8, _hoisted_1)
        ]),
        _: 1
      }, 8, ["content"]),
      _createVNode(WidgetCoach)
    ]),
    body: _withCtx(() => [
      _createVNode(ModalNotification, {
        "is-open": isConfirmClosingOpen.value,
        title: _ctx.$t('confirm_close.title'),
        message: _ctx.$t('confirm_close.description'),
        onOnClose: _cache[3] || (_cache[3] = ($event: any) => (isConfirmClosingOpen.value = false)),
        onOnConfirm: handleClose
      }, null, 8, ["is-open", "title", "message"]),
      _createElementVNode("form", {
        class: "modal-create__form",
        onSubmit: _withModifiers(createTask, ["prevent"])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(['modal-create__collapsable', { hidden: _unref(hasScheduledCall) }])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(UiInput, {
                "is-smart-value": _unref(dirtyState).executor,
                onInput: _cache[4] || (_cache[4] = ($event: any) => (_unref(dirtyState).executor = false)),
                disabled: isDisabledField.value,
                label: _ctx.$t('modal_task.to'),
                class: "modal-create__form-input",
                "model-value": _unref(currentData).executor?.fullName,
                icon: _unref(currentData).executor ? 'user-change' : 'contact',
                "icon-align": "right",
                readonly: "",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (isOpenAutoComplete.value = true))
              }, null, 8, ["is-smart-value", "disabled", "label", "model-value", "icon"]),
              (_unref(subordinates)?.length)
                ? (_openBlock(), _createBlock(UiAutocompleteUsers, {
                    key: 0,
                    items: _unref(subordinates),
                    "is-open": isOpenAutoComplete.value,
                    search: searchUsers.value,
                    "onUpdate:search": _cache[6] || (_cache[6] = ($event: any) => ((searchUsers).value = $event)),
                    onSelectItem: selectUser
                  }, null, 8, ["items", "is-open", "search"]))
                : _createCommentVNode("", true)
            ])), [
              [_directive_click_outside, hideAutocomplete]
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(PopupTaskDatePicker, _mergeProps(_unref(datePickerData), {
                disabled: isDisabledField.value || !_unref(currentData).executor?.id,
                "onUpdate:time": _cache[7] || (_cache[7] = ($event: any) => {
                  _unref(newTask).setTime($event);
                  _unref(dirtyState).deadline = false;
                }),
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => {
                  _unref(newTask).setDate($event);
                  _unref(dirtyState).deadline = false;
                })
              }), null, 16, ["disabled"])
            ])
          ])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(['modal-create__collapsable', { hidden: _unref(hasScheduledCall) }])
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(UiInput, {
              "is-smart-value": _unref(dirtyState).name,
              onInput: _cache[9] || (_cache[9] = ($event: any) => (_unref(dirtyState).name = false)),
              disabled: isDisabledField.value,
              class: _normalizeClass([
                'modal-create__wide-input',
                { error: _unref(currentData).name.length > 75 },
              ]),
              modelValue: _unref(currentData).name,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_unref(currentData).name) = $event)),
              label: _ctx.$t('modal_task.task_name_placeholder')
            }, null, 8, ["is-smart-value", "disabled", "class", "modelValue", "label"]),
            _createVNode(UiDropdownPriority, {
              class: "priority-dropdown modal-create__dropdown",
              modelValue: _unref(currentData).taskPriority,
              "onUpdate:modelValue": [
                _cache[11] || (_cache[11] = ($event: any) => ((_unref(currentData).taskPriority) = $event)),
                _cache[12] || (_cache[12] = ($event: any) => (_unref(dirtyState).taskPriority = false))
              ],
              items: _unref(priorityOptions),
              "is-smart-value": _unref(dirtyState).taskPriority
            }, null, 8, ["modelValue", "items", "is-smart-value"])
          ])
        ], 2),
        _createVNode(PopupTaskScheduleCall, {
          isOpen: _unref(hasScheduledCall),
          "onUpdate:isOpen": _cache[13] || (_cache[13] = ($event: any) => (_isRef(hasScheduledCall) ? (hasScheduledCall).value = $event : null))
        }, null, 8, ["isOpen"]),
        _createVNode(UiInput, {
          highlighted: !_unref(currentData).taskContent.length,
          class: _normalizeClass([
            'modal-create__area',
            { error: _unref(currentData).taskContent.length > 10_000 },
          ]),
          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (_unref(currentData).taskContent = $event)),
          "model-value": taskContentValue.value,
          type: "textarea",
          placeholder: _ctx.$t('modal_task.description_placeholder'),
          rows: "8",
          style: {"resize":"vertical"},
          ref_key: "contentInput",
          ref: contentInput
        }, null, 8, ["highlighted", "class", "model-value", "placeholder"]),
        _createVNode(UiUploaderFiles, {
          modelValue: _unref(currentData).files,
          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_unref(currentData).files) = $event))
        }, null, 8, ["modelValue"]),
        _cache[16] || (_cache[16] = _createElementVNode("input", {
          type: "submit",
          hidden: ""
        }, null, -1))
      ], 32)
    ]),
    footer: _withCtx(() => [
      _createVNode(ModalCreateTaskFooter, {
        onSubmit: createTask,
        isDisabledSubmit: isDisabledSubmit.value,
        isOpenModal: isOpen.value
      }, null, 8, ["isDisabledSubmit", "isOpenModal"])
    ]),
    _: 3
  }, 8, ["is-open", "title", "modal", "close-disabled"]))
}
}

})