import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { RawSuggestedTime } from '@/types/calls';
import { computed } from 'vue';
import { format, parse } from 'date-fns';

type Props = { time: RawSuggestedTime; active: boolean };

export default /*@__PURE__*/_defineComponent({
  __name: 'TimeItem',
  props: {
    time: {},
    active: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const timeView = computed(() => ({
  start: format(props.time.startTime, 'HH:mm'),
  end: format(props.time.finishTime, 'HH:mm'),
  day: format(parse(props.time.suggestDate, 'dd.MM.yyyy', new Date()), 'd MMM'),
}));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['suggested-time-item', { active: _ctx.active, best: props.time.better }])
  }, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createTextVNode(_toDisplayString(timeView.value.day) + " " + _toDisplayString(timeView.value.start) + " - " + _toDisplayString(timeView.value.end), 1)
    ])
  ], 2))
}
}

})