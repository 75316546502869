import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "participants-list" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "participants-list__credentials" }
const _hoisted_4 = { class: "disabled-text" }

import UiAvatar from '@/components/ui/UiAvatar.vue';
import { CallParticipant } from '@/hooks/useUsersSortAndSelect';

type Props = { list: CallParticipant[] };

export default /*@__PURE__*/_defineComponent({
  __name: 'ScheduleCallParticipantsList',
  props: {
    list: {}
  },
  emits: ['onSelect'],
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_TransitionGroup, {
      name: "swipe-y-group",
      appear: ""
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (user) => {
          return (_openBlock(), _createElementBlock("div", {
            key: user.email,
            onClick: ($event: any) => (_ctx.$emit('onSelect', user))
          }, [
            _createVNode(UiAvatar, {
              "avatar-link": user.avatar?.uuidLink,
              fallbackName: user.fullName,
              size: "3.6rem"
            }, null, 8, ["avatar-link", "fallbackName"]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", null, _toDisplayString(user.fullName), 1),
              _createElementVNode("span", _hoisted_4, _toDisplayString(user.email), 1)
            ])
          ], 8, _hoisted_2))
        }), 128))
      ]),
      _: 1
    })
  ]))
}
}

})