import { createRouter, createWebHistory } from 'vue-router';
import routes from '@/router/routes';
import { useUserStore } from '@/stores/user';
import { useTariffStore } from '@/stores/tariffs/tariffs';
import { storeToRefs } from 'pinia';
import { useWorkspaceStore } from '@/stores/workspaces';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { isLoggedIn } = storeToRefs(useUserStore());
  const billing = useTariffStore();
  const { tariffs, tariffsList, isCurrentTariffExpired, isTrial } =
    storeToRefs(billing);

  const requiredRequests: Promise<unknown>[] = [];

  if (isLoggedIn.value && !tariffs.value)
    requiredRequests.push(billing.fetchUserTariff());

  const workspaces = useWorkspaceStore();
  const { list } = storeToRefs(workspaces);
  if (isLoggedIn.value && !list.value.length)
    requiredRequests.push(workspaces.fetchList());

  await Promise.allSettled(requiredRequests);

  const isToTrialRoute = to.matched.find((r) => r.name === 'TrialView');
  const isToPurchaseRoute = to.matched.find((r) => r.name === 'PurchaseView');

  const redirectToTrialPageCondition = () => {
    return isLoggedIn.value && isTrial.value && !isToTrialRoute;
  };

  const redirectFromTrialPageCondition = () => {
    return (
      isLoggedIn.value &&
      tariffs.value?.purchaseDetailsList.length &&
      isToTrialRoute
    );
  };

  const redirectToPurchasePageCondition = () => {
    return (
      isLoggedIn.value &&
      tariffs.value?.purchaseDetailsList.length &&
      isCurrentTariffExpired.value &&
      !isToPurchaseRoute
    );
  };

  const getCurrentTariffParams = async () => {
    if (isLoggedIn.value && !tariffsList.value.length)
      await billing.fetchTariffList();

    const params = {
      tariffId:
        tariffs.value?.tariff.typeRefresh === 'month'
          ? tariffs.value.tariff.id
          : tariffsList.value.find(
              (t) => t.annualTariff?.id === tariffs.value?.tariff.id,
            )?.id,
    };
    const query = {
      isAnnually: String(tariffs.value?.tariff.typeRefresh === 'annual'),
    };
    return { params, query };
  };

  if (to.meta.public) {
    next();
  } else if (!to.meta.public && !isLoggedIn.value) {
    next({ name: 'Login' });
  } else if (redirectToTrialPageCondition()) {
    next({ name: 'TrialTariffId', params: { tariffId: 1 } });
  } else if (redirectFromTrialPageCondition()) {
    next({ name: 'Home' });
  } else if (redirectToPurchasePageCondition()) {
    next({ name: 'PurchaseTariffId', ...(await getCurrentTariffParams()) });
  } else next();
});

export default router;
