<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="users">
      <path
        id="Vector"
        d="M3.02087 7.03101C3.02087 8.84319 4.48994 10.3123 6.30212 10.3123C8.11431 10.3123 9.58337 8.84319 9.58337 7.03101C9.58337 5.21882 8.11431 3.74976 6.30212 3.74976C4.48994 3.74976 3.02087 5.21882 3.02087 7.03101Z"
        stroke="#778BEE"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        id="Vector_2"
        opacity="0.6"
        d="M11.572 4.76515C12.1393 4.29662 12.8668 4.01514 13.6601 4.01514C15.4723 4.01514 16.9413 5.4842 16.9413 7.29639C16.9413 9.10857 15.4723 10.5776 13.6601 10.5776C12.8668 10.5776 12.1393 10.2962 11.572 9.82763"
        stroke="#778BEE"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        id="Vector_3"
        d="M1.19775 17.0075C1.19775 16.3695 2.32404 11.9033 6.31819 11.9033C10.3123 11.9033 11.7707 16.3695 11.7707 17.0075"
        stroke="#778BEE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_4"
        opacity="0.6"
        d="M19.1287 17.2726C19.1287 16.6346 17.6703 12.1685 13.6762 12.1685C12.8727 12.1685 12.1853 12.3492 11.5997 12.6431"
        stroke="#778BEE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped></style>
