import { MeetingType } from '@/types/calls';

export const PLATFORMS: {
  value: MeetingType;
  label: string;
  icon: string;
  joinLabel: string;
  authLabel: string;
}[] = [
  {
    value: 'MEET',
    label: 'Google Meet',
    icon: require('@images/integrations/google-meet.svg'),
    joinLabel: 'Join with Google Meet',
    authLabel: 'Log in with Google',
  },
  // {
  //   value: 'ZOOM',
  //   label: 'Zoom',
  //   icon: require('@images/integrations/zoom.svg'),
  //   joinLabel: 'Join Zoom conference',
  //   authLabel: 'Connect Zoom',
  // },
];
