import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"
import _imports_0 from '@images/logo.svg'
import _imports_1 from '@images/logo-icon.svg'


const _hoisted_1 = {
  key: 0,
  class: "layout-coaches"
}
const _hoisted_2 = { class: "layout__header-right" }

import { computed, inject, Ref, ref, watchEffect } from 'vue';
import WidgetProfile from '@/components/widget/WidgetProfile.vue';
import WidgetNotification from '@/components/widget/WidgetNotification.vue';
import WidgetHeaderBalance from '@/components/widget/WidgetHeaderBalance.vue';
import UiBurger from '@/components/ui/UiBurger.vue';
import LayoutMobileAside from '@/components/layout/app/LayoutMobileAside.vue';
import MarketLinks from '@/components/market-links/MarketLinks.vue';
import WidgetTodo from '@/components/widget/WidgetTodo.vue';
import WidgetCoach from '@/components/widget/WidgetCoach.vue';
import { useCoachesStore } from '@/stores/coaches/coaches';
import { storeToRefs } from 'pinia';
import WidgetWorkSpaces from '@/components/widget/WidgetWorkSpaces.vue';
import { useRoute } from 'vue-router';
import WidgetAgenda from '@/components/widget/WidgetAgenda.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LayoutHeader',
  setup(__props) {

const route = useRoute();
const isOpen = ref(false);

const isMobileResolution = inject<Ref<boolean>>('isMobileResolution');
const isMobile = computed(
  () => isMobileResolution?.value || route.meta.noAside,
);
const padded = computed(() => route.meta.padded);

const coachesStore = useCoachesStore();
const { activeCard, isCoachesVisible } = storeToRefs(coachesStore);

watchEffect(() => {
  isOpen.value =
    isCoachesVisible.value &&
    !!isMobileResolution?.value &&
    !!activeCard.value.highlight?.includes('sidebar');
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['layout__header header', { padded: padded.value }])
  }, [
    _createVNode(_Transition, {
      name: "fade",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_unref(isCoachesVisible))
          ? (_openBlock(), _createElementBlock("div", _hoisted_1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(UiBurger, {
      onToggle: _cache[0] || (_cache[0] = ($event: any) => (isOpen.value = !isOpen.value)),
      "is-open": isOpen.value,
      class: _normalizeClass(['header__burger', { mobile: isMobile.value }]),
      style: _normalizeStyle(isOpen.value && 'display: flex'),
      onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
    }, null, 8, ["is-open", "class", "style"]),
    _createElementVNode("img", {
      class: "header__logo",
      src: _imports_0,
      alt: "",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push({ name: 'Home' })))
    }),
    _createElementVNode("img", {
      class: "header__logo-mobile",
      src: _imports_1,
      alt: "",
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push({ name: 'Home' })))
    }),
    (!_ctx.$route.meta.isAgenda)
      ? (_openBlock(), _createBlock(WidgetHeaderBalance, {
          key: 0,
          class: "header__widget-balance"
        }))
      : _createCommentVNode("", true),
    _createVNode(LayoutMobileAside, {
      "is-open": isOpen.value,
      onClose: _cache[4] || (_cache[4] = ($event: any) => (isOpen.value = !isOpen.value))
    }, {
      top: _withCtx(() => [
        (!_ctx.$route.meta.isAgenda)
          ? (_openBlock(), _createBlock(WidgetHeaderBalance, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["is-open"]),
    _createVNode(MarketLinks, {
      "large-buttons": "",
      "no-description": "",
      class: "layout__header-links"
    }),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.$route.meta.isAgenda)
        ? (_openBlock(), _createBlock(WidgetAgenda, { key: 0 }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(WidgetWorkSpaces),
            _createVNode(WidgetTodo)
          ], 64)),
      _createVNode(WidgetCoach),
      _createVNode(WidgetNotification),
      _createVNode(WidgetProfile)
    ])
  ], 2))
}
}

})