<script setup lang="ts">
import { RawSuggestedTime } from '@/types/calls';
import TimeItem from '@/components/popups/scheduleCall/TimeItem.vue';
import {
  addMinutes,
  differenceInDays,
  differenceInMinutes,
  format,
  parse,
} from 'date-fns';
import { onUnmounted, ref } from 'vue';

type Props = {
  times: RawSuggestedTime[];
  currentTime: { start: Date; end: Date };
  currentDate: Date;
};
const props = defineProps<Props>();

const isActive = (time: RawSuggestedTime) => {
  return !(
    differenceInMinutes(time.startTime, props.currentTime.start) ||
    differenceInMinutes(time.finishTime, props.currentTime.end) ||
    differenceInDays(
      parse(time.suggestDate, 'dd.MM.yyyy', new Date()),
      props.currentDate,
    )
  );
};

const setNow = (): RawSuggestedTime => ({
  startTime: new Date(),
  finishTime: addMinutes(new Date(), 30),
  suggestDate: format(new Date(), 'dd.MM.yyyy'),
  better: false,
});
const now = ref(setNow());
const interval = setInterval(() => (now.value = setNow()), 1000);
onUnmounted(() => clearInterval(interval));
</script>

<template>
  <div class="schedule-call-times">
    The best time to call
    <div class="schedule-call-times__content">
      <TimeItem
        :time="now"
        :active="isActive(now)"
        @click="$emit('onTimeChange', now)"
      >
        Now
      </TimeItem>
      <TimeItem
        v-for="t in times"
        :time="t"
        :active="isActive(t)"
        :key="t.startTime"
        @click="$emit('onTimeChange', t)"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.schedule-call-times {
  @include flex-column(1rem);
  cursor: pointer;

  &__content {
    @include flex-row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;

    & > * {
      width: calc(50% - 0.5rem);
    }
  }
}
</style>
