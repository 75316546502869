import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  class: "view-task-files",
  id: "shared-files"
}
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { class: "view-task-files__title" }
const _hoisted_4 = { class: "view-task-files__list" }
const _hoisted_5 = {
  key: 0,
  class: "view-task-files__progress"
}

import { storeToRefs } from 'pinia';
import { useViewTaskStore } from '@/stores/viewTask/viewTask';
import UiFile from '@/components/ui/UiFile.vue';
import { computed, ref } from 'vue';
import UiDropArea from '@/components/ui/UiDropArea.vue';
import { useImageFunctions } from '@/hooks/useImageFunctions';
import IconClose from '@/components/icon/IconClose.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalViewTaskFiles',
  props: {
    isReadonly: { type: Boolean }
  },
  setup(__props: any) {



const dropAreaRef = ref<InstanceType<typeof UiDropArea> | null>(null);

const viewTask = useViewTaskStore();
const { deleteFile, createMediaFile } = viewTask;
const { files, hasChanges, upload } = storeToRefs(viewTask);

const { uploadImage, deleteImage } = useImageFunctions(deleteFile);

const linksSet = computed(() =>
  files.value
    .filter(({ typeFile }) => typeFile === 'PHOTO')
    .map(({ link }) => link),
);

const onChange = (e: Event) => {
  const files = (e.target as HTMLInputElement).files;
  if (files) {
    createMediaFile(Array.from(files));
  }
};

const handleDrop = function (e: DragEvent) {
  const dt = e.dataTransfer;
  if (!dt?.files) return;
  createMediaFile(Array.from(dt.files));

  if (dropAreaRef.value) {
    dropAreaRef.value.setInactiveDropArea();
  }
};

return (_ctx: any,_cache: any) => {
  const _component_el_progress = _resolveComponent("el-progress")!
  const _directive_skeleton = _resolveDirective("skeleton")!

  return (_openBlock(), _createBlock(UiDropArea, {
    ref_key: "dropAreaRef",
    ref: dropAreaRef
  }, {
    default: _withCtx(({ isActiveDropArea }) => [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass([
            'view-task-files__content',
            { 'view-task-files__content--active': isActiveDropArea },
          ]),
          onDrop: _withModifiers(handleDrop, ["prevent"]),
          onDragenter: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
          onDragover: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"])),
          onDragleave: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
        }, [
          _createElementVNode("input", {
            ref: "inputFileRef",
            type: "file",
            multiple: "",
            disabled: _ctx.isReadonly,
            class: "view-task-files__control",
            onChange: onChange
          }, null, 40, _hoisted_2),
          _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.$t('files')), 1),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(files) || [], (file, i) => {
              return (_openBlock(), _createBlock(UiFile, {
                key: file.id,
                file: file,
                "links-set": linksSet.value,
                "initial-preview-id": i,
                "is-readonly": _ctx.isReadonly || _unref(viewTask).state.isShared,
                onDeleteImage: _cache[0] || (_cache[0] = ($event: any) => {
                _unref(deleteImage)($event);
                hasChanges.value = true;
              }),
                onUploadImage: _cache[1] || (_cache[1] = ($event: any) => (_unref(uploadImage)($event.link, $event?.name)))
              }, null, 8, ["file", "links-set", "initial-preview-id", "is-readonly"]))
            }), 128))
          ])
        ], 34),
        _createVNode(_Transition, {
          name: "fade",
          appear: "",
          duration: 100
        }, {
          default: _withCtx(() => [
            (_unref(upload).loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("div", null, [
                    _createElementVNode("span", null, _toDisplayString(_unref(upload).progress || '0') + "%", 1),
                    _cache[5] || (_cache[5] = _createElementVNode("span", null, "Loading...", -1)),
                    _createVNode(_component_el_progress, {
                      style: {"width":"30rem"},
                      percentage: _unref(upload).progress,
                      color: "var(--color-primary)",
                      "show-text": false,
                      "stroke-width": 10
                    }, null, 8, ["percentage"])
                  ]),
                  _createVNode(IconClose, {
                    style: {"color":"var(--color-disabled)","min-width":"4rem","min-height":"4rem","padding":"1rem","cursor":"pointer"},
                    onClick: _unref(upload).abort
                  }, null, 8, ["onClick"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])), [
        [_directive_skeleton, _unref(viewTask).state.isFileLoading]
      ])
    ]),
    _: 1
  }, 512))
}
}

})