<template>
  <div :class="['time-range', { disabled }]">
    <span>{{ $t('time.time') }}</span>
    <TimePicker
      :placeholder="$t('time.start')"
      :modelValue="startTime"
      @update:modelValue="onUpdateStartTime"
    />
    -
    <TimePicker
      :placeholder="$t('time.end')"
      :modelValue="endTime"
      @update:modelValue="onUpdateEndTime"
      :start="startTime"
    />
  </div>
</template>

<script setup lang="ts">
import TimePicker from '@/components/time/TimePicker.vue';
import { isBefore } from 'date-fns';

type Props = { startTime?: Date; endTime?: Date; disabled?: boolean };
const props = defineProps<Props>();

const emits = defineEmits(['update:startTime', 'update:endTime']);

const onUpdateStartTime = (time?: Date) => {
  if (!time || !props.endTime) return;
  if (isBefore(props.endTime, time)) emits('update:endTime', time);
  emits('update:startTime', time);
};

const onUpdateEndTime = (time?: Date) => {
  if (!time || !props.startTime) return;
  if (isBefore(time, props.startTime)) emits('update:startTime', time);
  emits('update:endTime', time);
};
</script>

<style scoped lang="scss">
.time-range {
  @include flex-row(1rem);
  justify-content: center;
  align-items: center;
  transition: all 0.15s ease-in-out;
  width: 100%;

  & > span {
    margin-right: auto;
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }
}
</style>
