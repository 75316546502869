import { ApiService } from '@/api/services/core';
import { axiosInstance, axiosRefreshInstance } from '@/api/axios';
import { ENDPOINTS } from '@/api/endpoints';
import { ThirdPartyRequestParams, User } from '@/types/user';
import { LangUI } from '@/types/lang';

type UserEdit = {
  firstName?: string;
  lastName?: string;
  position?: string;
  locale?: any;
  uiLocale?: LangUI['abbreviation'];
};

export class AuthService extends ApiService {
  setUserAvatar(params: { fileId: number }) {
    return axiosInstance.post(ENDPOINTS.auth.setUserAvatar, null, { params });
  }

  login(payload: { username: string; password: string }) {
    return axiosInstance.post(ENDPOINTS.auth.login, payload);
  }

  thirdPartiesLogin(auth: ThirdPartyRequestParams) {
    const params = { web: true };
    return axiosInstance.post(ENDPOINTS.auth.thirdParties, auth, { params });
  }

  registration(payload: Partial<User>) {
    const params = { web: true };
    return axiosInstance.post<User>(ENDPOINTS.auth.registration(), payload, {
      params,
    });
  }

  changeUserInfo(payload: UserEdit) {
    return axiosInstance.put<User>(ENDPOINTS.auth.changeUserInfo, payload);
  }

  sendVerificationEmail(params: { email: string }) {
    return axiosInstance.post<any>(ENDPOINTS.auth.sendVerificationEmail, null, {
      params,
    });
  }

  sendResetPassEmail(params: { email: string }) {
    return axiosInstance.post<any>(ENDPOINTS.auth.resetPassEndpoint, null, {
      params,
    });
  }

  passChange(params: { password: string; uuid: string }) {
    return axiosInstance.post<any>(ENDPOINTS.auth.resetPassChange, null, {
      params,
    });
  }

  refresh(refreshToken?: string) {
    return axiosRefreshInstance.post(ENDPOINTS.auth.refresh, { refreshToken });
  }

  userData() {
    return axiosInstance.get<User>(ENDPOINTS.auth.user);
  }

  deleteUser() {
    return axiosInstance.delete<undefined>(ENDPOINTS.auth.deleteUser());
  }
}
