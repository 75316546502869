import { useWorkspaceStore } from '@/stores/workspaces';
import { invoke, until, watchPausable } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { sleep } from '@/utils/common';

interface Params {
  mount: boolean;
  change: boolean;
}

const defaults: Params = {
  mount: true,
  change: true,
};

export const onBeforeMountAndWorkspaceChange = async (
  cb: () => Promise<unknown>,
  params: Partial<Params> = {},
) => {
  const config = Object.assign(defaults, params);

  const workspacesStore = useWorkspaceStore();
  const { currentWorkspace } = storeToRefs(workspacesStore);

  const trigger = (val?: number) => {
    if (!val) return;
    else cb();
  };

  const { pause, resume } = watchPausable(
    () => currentWorkspace.value?.id,
    trigger,
    { immediate: false },
  );
  pause();

  await invoke(async () => {
    await until(() => currentWorkspace.value?.id).toBeTruthy();
    if (config.change) resume();
    if (config.mount) {
      await cb();
      await sleep(300);
    }
  });
};
