<template>
  <div class="modal-body-view" :class="{ divided: state.isShowComments }">
    <div class="modal-body-view__container">
      <div
        :class="[
          'modal-body-view__collapsable',
          { hidden: state.hasScheduledCall },
        ]"
      >
        <div class="modal-body-view__group modal-body-view__row">
          <div
            v-click-outside="hideAutocomplete"
            v-skeleton.large="state.isTaskLoading"
            style="position: relative"
          >
            <ui-input
              :label="$t('modal_task.to')"
              icon="user-change"
              icon-align="right"
              :model-value="executor"
              readonly
              @click="isOpenAutoComplete = true"
            />
            <UiAutocompleteUsers
              v-if="subordinates && !state.isShared && !isReadonly"
              :items="subordinates"
              :is-open="isOpenAutoComplete"
              v-model:search="searchUsers"
              @select-item="selectUser"
            />
          </div>
          <div
            class="modal-body-view__col"
            v-skeleton.large="state.isTaskLoading"
          >
            <PopupTaskDatePicker
              v-bind="datePickerData"
              :is-share="state.isShared"
              :is-readonly="isReadonly"
              @update:time="
                setTime($event);
                hasChanges = true;
              "
              @update:modelValue="
                setDate($event);
                hasChanges = true;
              "
            />
          </div>
        </div>
      </div>
      <div
        :class="[
          'modal-body-view__collapsable',
          { hidden: state.hasScheduledCall },
        ]"
      >
        <div class="modal-body-view__group modal-body-view__row">
          <ui-input
            v-skeleton.large="state.isTaskLoading"
            :model-value="activeTask.name"
            @update:model-value="setActiveTaskFields('name', $event)"
            maxLength="75"
            :label="$t('modal_task.task_name')"
            :readonly="isReadonly"
          />
          <UiDropdownPriority
            v-skeleton.large="state.isTaskLoading"
            class="priority-dropdown"
            style="max-width: 14rem"
            :disabled="isReadonly"
            :model-value="{
              title: activeTask.taskPriority,
              value: activeTask.taskPriority,
            }"
            @update:model-value="
              setActiveTaskFields('taskPriority', $event.value)
            "
            :items="priorityOptions"
          />
        </div>
      </div>
      <PopupTaskScheduleCall
        v-if="state.hasScheduledCall"
        class="modal-body-view__call"
        :isOpen="state.hasScheduledCall"
        readOnly
      />
      <div class="modal-body-view__group" id="view-share">
        <ui-input
          class="modal-body-view__description"
          v-skeleton.large="state.isTaskLoading"
          :model-value="activeTask.taskContent"
          @update:model-value="setActiveTaskFields('taskContent', $event)"
          type="textarea"
          :readonly="isReadonly"
          maxLength="10000"
          style="resize: vertical"
          rows="8"
        />
      </div>
      <ModalViewTaskFiles
        :is-readonly="isReadonly"
        class="modal-body-view__group modal-body-view__upload"
      />
    </div>
    <div class="modal-body-view__comments">
      <ModalViewCommentsList />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';

import { useViewTaskStore } from '@/stores/viewTask/viewTask';
import ModalViewTaskFiles from '@/components/modals/Tasks/ModalViewTask/ModalViewTaskFiles.vue';
import ModalViewCommentsList from '@/components/modals/Tasks/ModalViewTask/ModalViewCommentsList.vue';
import UiInput from '@/components/control/UiInput.vue';
import UiAutocompleteUsers from '@/components/ui/UiAutocomplete/UiAutocompleteUsers.vue';
import { useNewTaskStore } from '@/stores/newTask';
import { SubordinateViewType } from '@/utils/subordinates';
import { concatName } from '@/utils/text';
import UiDropdownPriority from '@/components/ui/UiDropdown/UiDropdownPriority.vue';
import { priorityOptions } from '@/consts';
import PopupTaskDatePicker from '@/components/popups/PopupTaskDatePicker.vue';
import PopupTaskScheduleCall from '@/components/popups/scheduleCall/PopupTaskScheduleCall.vue';
import { useCallStore } from '@/stores/call';
import { whenever } from '@vueuse/core';

const runtimeValue = ref('');

const viewTask = useViewTaskStore();
const { isReadonly, activeTask, hasChanges, datePickerData, state } =
  storeToRefs(viewTask);

const { setActiveTaskFields, setDate, setTime } = viewTask;

const newTask = useNewTaskStore();
const { getSubordinates } = newTask;
const { subordinates, isSubordinatesFetching } = storeToRefs(newTask);

const call = useCallStore();
whenever(() => state.value.hasScheduledCall, call.init);

const executor = computed(() =>
  concatName(
    activeTask.value.executor?.firstName,
    activeTask.value.executor?.lastName,
  ),
);
const isOpenAutoComplete = ref(false);
const searchUsers = ref('');

const selectUser = (user: SubordinateViewType) => {
  if (user.id === activeTask.value.executor?.id)
    return (isOpenAutoComplete.value = false);
  setActiveTaskFields('executor', {
    ...user,
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
  });
  isOpenAutoComplete.value = false;
};

const hideAutocomplete = () => {
  isOpenAutoComplete.value = false;
};

watch(
  () => activeTask.value.id,
  () => {
    runtimeValue.value = activeTask.value.taskContent || '';
  },
);

onMounted(
  () =>
    !state.value.isShared && !isSubordinatesFetching.value && getSubordinates(),
);
</script>
<style lang="scss" scoped>
.modal-body-view {
  display: grid;
  grid-template-columns: 1fr 0fr;
  height: 100%;
  transition: all 0.5s ease-in-out;
  overflow-x: auto;

  &.divided {
    grid-template-columns: 64fr 39fr;

    @media (max-width: 850px) {
      grid-template-columns: 0fr 1fr;
    }
  }

  &__call {
    padding: 0 3rem;
  }

  &__collapsable {
    display: grid;
    grid-template-rows: 1fr;
    transition: all 0.15s ease-in-out;

    &.hidden {
      grid-template-rows: 0fr;
      margin-bottom: -2rem;

      & > * {
        overflow: hidden;
      }
    }
  }

  &__container {
    @include flex-column(2rem);
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 3rem 0 4rem;
    margin-bottom: 7rem;
    background: #fcfcfc;
  }

  &__skeleton {
    > * {
      height: 5rem;
      width: 100%;
    }
  }

  &__description {
    :deep(textarea) {
      height: 37rem;

      @media (max-width: 1440px) {
        height: 30rem;
      }
    }
  }

  &__comments {
    display: grid;
    overflow: hidden;
    height: 100%;
    border-left: 0.2rem solid transparent;
    transition: border-left-color 0.5s ease-in-out;

    .divided & {
      border-left: 0.2rem solid #dde3ec;
    }
  }

  &__group {
    padding: 0 3rem;

    @media (max-width: 640px) {
      padding: 0 1.6rem;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    column-gap: 2rem;

    @media (max-width: 640px) {
      flex-direction: column;
      row-gap: 2rem;
    }

    > * {
      flex: 1;
      min-width: fit-content;

      @media (max-width: 640px) {
        min-width: 100%;
      }
    }
  }

  &__col {
    width: 50%;
  }

  &__upload {
    flex: 1;
  }
}
</style>
