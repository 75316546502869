<script setup lang="ts">
import { RawSuggestedTime } from '@/types/calls';
import { computed } from 'vue';
import { format, parse } from 'date-fns';

type Props = { time: RawSuggestedTime; active: boolean };
const props = defineProps<Props>();

const timeView = computed(() => ({
  start: format(props.time.startTime, 'HH:mm'),
  end: format(props.time.finishTime, 'HH:mm'),
  day: format(parse(props.time.suggestDate, 'dd.MM.yyyy', new Date()), 'd MMM'),
}));
</script>

<template>
  <div :class="['suggested-time-item', { active, best: props.time.better }]">
    <slot>{{ timeView.day }} {{ timeView.start }} - {{ timeView.end }}</slot>
  </div>
</template>

<style scoped lang="scss">
.suggested-time-item {
  display: grid;
  place-content: center;
  padding: 0.8rem;
  background: #e7efff;
  border-radius: 4.7rem;
  border: 0.1rem solid transparent;
  @include f12;
  width: fit-content;
  transition: all 0.2s ease-in-out;

  &.active {
    background: var(--color-primary);
    color: #fff;
  }

  &.best {
    border-color: var(--color-primary);
  }
}
</style>
