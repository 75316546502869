import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import services from '@/api/services';
import { ITariff, ITariffItemResponse } from '@/types/tariffs';
import { fromUnixTime, secondsToMinutes } from 'date-fns';
import { formatBytes } from '@/utils/convert';
import { useCurrentLanguage } from '@/hooks/useCurrentLanguage';
import { numericSort } from '@/utils/common';
import _ from 'lodash';
import { PaymentMethod } from '@stripe/stripe-js';

export type TariffsViewType = Partial<ITariff> & {
  content: Record<string, any>;
};

export const useTariffStore = defineStore('tariff', () => {
  const tariffs = ref<ITariff | null>(null);
  const pending = ref(false);
  const error = ref(false);

  const rawTariffsList = ref<ITariffItemResponse[]>([]);
  const tariffsList = ref<ITariffItemResponse[]>([]);
  const isTariffsListLoading = ref(false);
  const isPlansModalVisible = ref(false);

  const paymentCard = ref<PaymentMethod>();
  const isPaymentCardLoaded = ref(false);

  const { formatWithLocale } = useCurrentLanguage();

  const invitationsLeft = computed(() => {
    return Number(tariffs.value?.amountPersonal);
  });

  const workspacesLeft = computed(() => {
    return Number(tariffs.value?.amountWorkSpace);
  });

  const isTrial = computed(
    () =>
      !tariffs.value?.purchaseDetailsList.length && !tariffs.value?.autoRenewal,
  );

  const paymentCardInfo = computed(() => ({
    last4: paymentCard.value?.card?.last4,
    brand: paymentCard.value?.card?.brand.toUpperCase(),
  }));

  const isCurrentTariffExpired = computed(() => {
    if (process.env.NODE_ENV !== 'production') return false;
    const expireDate = tariffs.value?.expireDateTimestamp;
    return (
      new Date(fromUnixTime(expireDate || 0)).getTime() < new Date().getTime()
    );
  });

  const tariffsView = computed<TariffsViewType>(() => {
    if (!tariffs.value) return { content: {} };

    const recognitionAmount = secondsToMinutes(
      Number(tariffs.value?.amountSeconds),
    );

    const content = {
      name: tariffs.value?.tariff?.name ?? '',
      storageLeft: formatBytes(tariffs.value?.amountBytes ?? 0),
      ends: formatWithLocale(
        tariffs.value?.expireDateTimestamp || 0,
        'dd.MM.yyyy',
      ),
      invitationsLeft: tariffs.value?.amountPersonal,

      recognitionLeft: `${recognitionAmount}`,
      recognitionLeftHeader: recognitionAmount,
      recognitionPercent: Math.round(
        (100 -
          tariffs.value?.amountSeconds / tariffs.value?.tariff.amountSeconds) *
          100,
      ),
      price: tariffs.value.tariff.price,
      currency: tariffs.value.tariff.currency,
      typeRefresh: tariffs.value.tariff.typeRefresh,
      workSpaceLeft: tariffs.value.amountWorkSpace,
      smsShareLeft: tariffs.value.amountSmsToShare,
      renewal: tariffs.value.autoRenewal,
    };

    return Object.assign({ content }, tariffs.value);
  });

  const fetchUserTariff = async () => {
    try {
      pending.value = true;
      error.value = false;
      const res = await services.tariffs.getUserTariff();
      tariffs.value = res.data;
    } catch (e) {
      console.log(e);
      error.value = true;
    } finally {
      pending.value = false;
    }
  };

  const removeUserTariff = () => (tariffs.value = null);

  const fetchTariffList = async () => {
    try {
      isTariffsListLoading.value = true;
      const { data } = await services.tariffs.getTariffList();
      rawTariffsList.value = data;

      const groupTariffsByPeriod = _.groupBy(data, 'typeRefresh');
      tariffsList.value = groupTariffsByPeriod.month.map((monthTariff) => {
        const annualTariff = groupTariffsByPeriod.annual.find(
          ({ name }) => monthTariff.name === name,
        );
        monthTariff.annualTariff = annualTariff || undefined;
        return monthTariff;
      });

      numericSort(tariffsList.value, 'price');
    } catch (e) {
      console.error(e);
    } finally {
      isTariffsListLoading.value = false;
    }
  };

  const getPaymentCard = async () => {
    try {
      const { data } = await services.purchase.getPaymentCard();
      paymentCard.value = data?.[0];
    } catch (e) {
      console.error(e);
    } finally {
      isPaymentCardLoaded.value = true;
    }
  };

  return {
    invitationsLeft,
    workspacesLeft,
    rawTariffsList,
    tariffs,
    fetchUserTariff,
    fetchTariffList,
    removeUserTariff,
    getPaymentCard,
    tariffsView,
    pending,
    error,
    tariffsList,
    isTariffsListLoading,
    paymentCardInfo,
    isCurrentTariffExpired,
    isPlansModalVisible,
    isPaymentCardLoaded,
    isTrial,
  };
});
