<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.1311 11.617C22.1789 11.6146 23.1422 12.1898 23.6346 13.1119C24.146 14.0153 24.1189 15.1256 23.5639 16.0031L17.1001 25.6637C16.4282 26.7461 15.1124 27.2455 13.8887 26.8828C12.665 26.52 11.8366 25.385 11.8669 24.1124V20.093H8.84005C7.82741 20.097 6.88986 19.561 6.3818 18.6875C5.87374 17.8141 5.87267 16.7365 6.379 15.862L12.9418 4.41031C13.4466 3.5385 14.3791 3.00103 15.3887 3C16.951 3 18.2175 4.26284 18.2175 5.82062V11.617H21.1311ZM15.2614 24.4931L21.7252 14.8325C21.8893 14.6148 21.913 14.3222 21.786 14.0811C21.659 13.8399 21.4041 13.6932 21.1311 13.7043H17.5103C16.7291 13.7043 16.0959 13.0728 16.0959 12.294V5.79242C16.0959 5.60293 16.0195 5.4214 15.8838 5.28874C15.7481 5.15609 15.5646 5.08347 15.3746 5.08726C15.1156 5.08814 14.8797 5.23584 14.7664 5.46804L8.20357 16.9198C8.08352 17.1386 8.08829 17.4043 8.21611 17.6186C8.34393 17.833 8.5758 17.9641 8.82591 17.9634H12.5599C13.3411 17.9634 13.9743 18.5948 13.9743 19.3737V24.0983C13.9781 24.4046 14.1798 24.6735 14.4735 24.7636C14.7672 24.8537 15.0856 24.7444 15.2614 24.4931Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped></style>
