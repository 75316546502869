import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

import WidgetShareTask from '@/components/widget/WidgetShareTask.vue';
import IconShare from '@/components/icon/IconShare.vue';
import IconMessage from '@/components/icon/IconMessage.vue';
import IconClose from '@/components/icon/IconClose.vue';
import { computed, ref } from 'vue';
import HeaderActionButton from './HeaderActionButton.vue';
import IconQuestion from '@/components/icon/IconQuestion.vue';
import { useCoachesStore } from '@/stores/coaches/coaches';
import IconFavorite from '@/components/icon/IconFavorite.vue';
import { useViewTaskStore } from '@/stores/viewTask/viewTask';
import { storeToRefs } from 'pinia';
import { useDebounceFn } from '@vueuse/core';
import IconTrash from '@/components/icon/IconTrash.vue';
import { useUserStore } from '@/stores/user';
import ModalNotification from '@/components/modals/ModalNotification.vue';
import { useTasksStore } from '@/stores/tasks/tasks';


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderActions',
  props: {
    readOnly: { type: Boolean }
  },
  setup(__props: any) {



const toggleComments = () => {
  if (state.value.isShowComments) {
    viewTask.closeComments();
    useDebounceFn(viewTask.clearComments, 500)();
  } else {
    viewTask.openComments();
    viewTask.getComments();
  }
};

const couchStore = useCoachesStore();
const shareLink = ref(false);

const viewTask = useViewTaskStore();
const { state, activeTask, upload } = storeToRefs(viewTask);

const user = useUserStore();
const { userData } = storeToRefs(user);

const tasks = useTasksStore();

const isDeletable = computed(
  () => activeTask.value.assigner?.id === userData.value?.id,
);
const isConfirmDeletionOpen = ref(false);
const onDeletionConfirmed = async () => {
  isConfirmDeletionOpen.value = false;
  if (!activeTask.value.id) return;
  await viewTask.remove(activeTask.value);
  await viewTask.clear();
  await tasks.updateTasksList();
};

const setShareLinkVisible = (val: boolean) => (shareLink.value = val);

const onClose = () => {
  state.value.isOpenModal && viewTask.clear();
  upload.value.abort();
};

return (_ctx: any,_cache: any) => {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.wrapper)
  }, [
    (isDeletable.value && !_ctx.readOnly)
      ? (_openBlock(), _createBlock(HeaderActionButton, {
          key: 0,
          tooltip: "Delete task",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (isConfirmDeletionOpen.value = true))
        }, {
          default: _withCtx(() => [
            _createVNode(IconTrash, { width: "20" })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!_unref(state).isShared)
      ? (_openBlock(), _createBlock(_component_el_tooltip, {
          key: 1,
          content: "Add task to favourites",
          placement: "bottom",
          disabled: _unref(viewTask).activeTask.isMarked,
          effect: "customized"
        }, {
          default: _withCtx(() => [
            _createVNode(IconFavorite, {
              class: _normalizeClass(_ctx.$style.favorite),
              isActive: _unref(viewTask).activeTask.isMarked,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(viewTask).setActiveTaskFields('isMarked', !_unref(activeTask).isMarked)))
            }, null, 8, ["class", "isActive"])
          ]),
          _: 1
        }, 8, ["disabled"]))
      : _createCommentVNode("", true),
    _createVNode(HeaderActionButton, {
      active: _unref(state).isShowComments,
      tooltip: 
        _ctx.$t(
          !_unref(state).isShowComments
            ? 'view_modal_task.comments'
            : 'view_modal_task.hide_comments',
        )
      ,
      onClick: toggleComments
    }, {
      default: _withCtx(() => [
        _createVNode(IconMessage)
      ]),
      _: 1
    }, 8, ["active", "tooltip"]),
    (!_unref(state).isShared && !_unref(state).isTaskLoading)
      ? (_openBlock(), _createBlock(_component_el_popover, {
          key: 2,
          visible: shareLink.value,
          "onUpdate:visible": setShareLinkVisible,
          placement: "bottom",
          "popper-class": "share__popup",
          width: "100%",
          trigger: "click"
        }, {
          reference: _withCtx(() => [
            _createElementVNode("div", null, [
              (!_unref(state).isTaskLoading)
                ? (_openBlock(), _createBlock(HeaderActionButton, {
                    key: 0,
                    tooltip: _ctx.$t('view_modal_task.share')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(IconShare)
                    ]),
                    _: 1
                  }, 8, ["tooltip"]))
                : _createCommentVNode("", true)
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(WidgetShareTask, {
              onOnClose: _cache[2] || (_cache[2] = ($event: any) => (setShareLinkVisible(false)))
            })
          ]),
          _: 1
        }, 8, ["visible"]))
      : _createCommentVNode("", true),
    _createVNode(HeaderActionButton, {
      tooltip: _ctx.$t('tooltips'),
      active: _unref(couchStore).isCoachesEnabled,
      onClick: _unref(couchStore).resolveCoach
    }, {
      default: _withCtx(() => [
        _createVNode(IconQuestion, {
          width: "18",
          height: "18"
        })
      ]),
      _: 1
    }, 8, ["tooltip", "active", "onClick"]),
    (!_unref(state).isShared)
      ? (_openBlock(), _createElementBlock("button", {
          key: 3,
          class: _normalizeClass(_ctx.$style.close),
          onClick: onClose
        }, [
          _createVNode(IconClose)
        ], 2))
      : _createCommentVNode("", true),
    _createVNode(ModalNotification, {
      "append-to-body": "",
      "is-open": isConfirmDeletionOpen.value,
      message: "In this case, the task and all information will be deleted forever.",
      yes: "Delete",
      width: "40rem",
      onOnClose: _cache[3] || (_cache[3] = ($event: any) => (isConfirmDeletionOpen.value = false)),
      onOnConfirm: onDeletionConfirmed
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.deleteConfirmHeader)
        }, [
          _createVNode(IconTrash, { color: "var(--color-danger)" }),
          _cache[4] || (_cache[4] = _createTextVNode(" Do you want to delete the task? "))
        ], 2)
      ]),
      _: 1
    }, 8, ["is-open"])
  ], 2))
}
}

})