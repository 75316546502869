import { RouteRecordRaw } from 'vue-router';
const SettingsView = () => import('@/views/settings/settings.vue');
const SettingsProfileView = () =>
  import('@/views/settings/profile/profile.vue');
const SettingsProfileIndex = () => import('@/views/settings/profile/index.vue');
const SettingsProfileMain = () => import('@/views/settings/profile/main.vue');
const SettingsProfileBillingIndex = () => import('@/views/billing/index.vue');

const SettingsIntegrationsView = () =>
  import('@/views/settings/integrations/integrations.vue');
const SettingsIntegrationsIndex = () =>
  import('@/views/settings/integrations/index.vue');
const SettingsIntegrationsCalendarsView = () =>
  import('@/views/settings/integrations/calendars/calendars.vue');
const SettingsIntegrationsCalendarsIndex = () =>
  import('@/views/settings/integrations/calendars/index.vue');
const SettingsIntegrationsCalendarsGoogle = () =>
  import('@/views/settings/integrations/calendars/google.vue');
const SettingsIntegrationsCommunicationView = () =>
  import('@/views/settings/integrations/communication/communication.vue');
const SettingsIntegrationsCommunicationIndex = () =>
  import('@/views/settings/integrations/communication/index.vue');
const SettingsIntegrationsCommunicationZoom = () =>
  import('@/views/settings/integrations/communication/zoom.vue');
const SettingsIntegrationsCommunicationTelegram = () =>
  import('@/views/settings/integrations/communication/telegram.vue');

const SettingsIntegrationsProductivityView = () =>
  import('@/views/settings/integrations/productivity/productivity.vue');
const SettingsIntegrationsProductivityIndex = () =>
  import('@/views/settings/integrations/productivity/index.vue');
const SettingsIntegrationsProductivityGpt = () =>
  import('@/views/settings/integrations/productivity/gpt.vue');

const communicationRoutes: RouteRecordRaw = {
  path: 'communication',
  meta: { breadCrumb: 'Communication' },
  name: 'SettingsIntegrationsCommunicationView',
  component: SettingsIntegrationsCommunicationView,
  children: [
    {
      path: '',
      name: 'SettingsIntegrationsCommunicationIndex',
      component: SettingsIntegrationsCommunicationIndex,
    },
    {
      path: 'zoom',
      meta: { breadCrumb: 'Zoom' },
      name: 'SettingsIntegrationsCommunicationZoom',
      component: SettingsIntegrationsCommunicationZoom,
    },
    {
      path: 'telegram',
      meta: { breadCrumb: 'Telegram' },
      name: 'SettingsIntegrationsCommunicationTelegram',
      component: SettingsIntegrationsCommunicationTelegram,
    },
  ],
};

const calendarsRoutes: RouteRecordRaw = {
  path: 'calendars',
  name: 'SettingsIntegrationsCalendarsView',
  component: SettingsIntegrationsCalendarsView,
  meta: { breadCrumb: 'Calendars' },
  children: [
    {
      path: '',
      name: 'SettingsIntegrationsCalendarsIndex',
      component: SettingsIntegrationsCalendarsIndex,
    },
    {
      path: 'google',
      meta: { breadCrumb: 'Google' },
      name: 'SettingsIntegrationsCalendarsGoogle',
      component: SettingsIntegrationsCalendarsGoogle,
    },
  ],
};

const productivityRoutes: RouteRecordRaw = {
  path: 'productivity',
  meta: { breadCrumb: 'Productivity' },
  name: 'SettingsIntegrationsProductivityView',
  component: SettingsIntegrationsProductivityView,
  children: [
    {
      path: '',
      name: 'SettingsIntegrationsProductivityIndex',
      component: SettingsIntegrationsProductivityIndex,
    },
    {
      path: 'gpt-chat',
      meta: { breadCrumb: 'GPT Chat' },
      name: 'SettingsIntegrationsProductivityGpt',
      component: SettingsIntegrationsProductivityGpt,
    },
  ],
};

export default {
  path: '/settings',
  component: SettingsView,
  name: 'Settings',
  redirect: { name: 'SettingsProfile' },
  meta: { layout: 'default' },
  children: [
    {
      path: 'profile',
      name: 'SettingsProfile',
      redirect: { name: 'SettingsProfileIndex' },
      component: SettingsProfileView,
      children: [
        {
          path: '',
          name: 'SettingsProfileIndex',
          meta: { breadCrumb: 'Personal settings' },
          redirect: { name: 'SettingsProfileMain' },
          component: SettingsProfileIndex,
          children: [
            {
              path: 'main',
              meta: { breadCrumb: 'Profile' },
              name: 'SettingsProfileMain',
              component: SettingsProfileMain,
            },
            {
              path: 'billing',
              meta: { breadCrumb: 'Billing' },
              component: SettingsProfileBillingIndex,
            },
          ],
        },
      ],
    },
    {
      path: 'integrations',
      name: 'SettingsIntegrations',
      redirect: { name: 'SettingsIntegrationsIndex' },
      component: SettingsIntegrationsView,
      children: [
        {
          path: '',
          name: 'SettingsIntegrationsIndex',
          meta: { breadCrumb: 'Integrations' },
          redirect: { name: 'SettingsIntegrationsCalendarsIndex' },
          component: SettingsIntegrationsIndex,
          children: [communicationRoutes, calendarsRoutes, productivityRoutes],
        },
      ],
    },
  ],
} as RouteRecordRaw;
