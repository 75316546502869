import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "create-note-modal__form" }

import UiModal from '@/components/ui/UiModal.vue';
import { useVModel } from '@vueuse/core';
import UiInput from '@/components/control/UiInput.vue';
import CreateNoteModalFooter from '@/components/notes/create/CreateNoteModalFooter.vue';
import { useCreateNoteStore } from '@/stores/notes/useCreateNoteStore';
import { storeToRefs } from 'pinia';
import IconTrash from '@/components/icon/IconTrash.vue';
import WidgetCoach from '@/components/widget/WidgetCoach.vue';
import { useCoachesStore } from '@/stores/coaches/coaches';
import { ref, watch } from 'vue';
import { CoachName } from '@/types/coaches';
import ModalNotification from '@/components/modals/ModalNotification.vue';
import IconSmart from '@/components/icon/IconSmart.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateNoteModal',
  props: {
    modelValue: { type: Boolean }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const isOpen = useVModel(props, 'modelValue', emit);

const createNoteStore = useCreateNoteStore();
const {
  state,
  isLoading,
  audio,
  isPrefilling,
  fieldIsGenerated,
  isManualPlanningDisabled,
  isChanged,
} = storeToRefs(createNoteStore);

const isConfirmClosingOpen = ref(false);

const handleCloseConfirm = () => {
  if (!isChanged.value) {
    handleClose();
    return;
  }
  isConfirmClosingOpen.value = true;
};

const handleClose = () => {
  isConfirmClosingOpen.value = false;
  isOpen.value = false;
  createNoteStore.$reset();
};

const { isCoachesEnabled } = useCouch();

function useCouch() {
  const coachesStore = useCoachesStore();
  const { setCoach } = coachesStore;
  const { activeCoach, isCoachesEnabled } = storeToRefs(coachesStore);

  const cashedCoach = ref<CoachName | null>(null);

  watch(
    () => isOpen.value,
    (value) => {
      if (value) {
        cashedCoach.value = activeCoach.value.name;
        setCoach('createNote');
      } else {
        cashedCoach.value && setCoach(cashedCoach.value);
      }
    },
  );

  return { isCoachesEnabled };
}

return (_ctx: any,_cache: any) => {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createBlock(UiModal, {
    "is-open": _unref(isOpen),
    onOnCloseClick: handleCloseConfirm,
    "close-on-click-modal": false,
    "close-on-press-escape": false,
    "close-disabled": true,
    "no-scroll": _unref(isCoachesEnabled),
    title: _ctx.$t('notes.creating.title'),
    class: "create-note-modal"
  }, {
    headerActions: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass([
          'create-note-modal__smart',
          { disabled: _unref(isManualPlanningDisabled) },
        ]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(createNoteStore).generate(_unref(state).description)))
      }, [
        _createTextVNode(_toDisplayString(_ctx.$t('create_modal_task.smart_planning')), 1),
        _createVNode(IconSmart)
      ], 2),
      _createVNode(_component_el_tooltip, {
        content: _ctx.$t('clear_all'),
        effect: "customized",
        placement: "top"
      }, {
        default: _withCtx(() => [
          _createElementVNode("button", {
            class: "create-note-modal__reset",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_unref(createNoteStore).resetState && _unref(createNoteStore).resetState(...args))),
            disabled: _unref(audio).isRecording
          }, [
            _createVNode(IconTrash)
          ], 8, _hoisted_1)
        ]),
        _: 1
      }, 8, ["content"]),
      _createVNode(WidgetCoach)
    ]),
    body: _withCtx(() => [
      _createVNode(ModalNotification, {
        "is-open": isConfirmClosingOpen.value,
        title: _ctx.$t('confirm_close.title'),
        message: _ctx.$t('confirm_close.description'),
        onOnClose: _cache[2] || (_cache[2] = ($event: any) => (isConfirmClosingOpen.value = false)),
        onOnConfirm: handleClose
      }, null, 8, ["is-open", "title", "message"]),
      _createElementVNode("form", _hoisted_2, [
        _createVNode(UiInput, {
          modelValue: _unref(state).title,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(state).title) = $event)),
          "is-smart-value": _unref(fieldIsGenerated)('title'),
          placeholder: _ctx.$t('notes.creating.name_placeholder'),
          disabled: _unref(isLoading) || _unref(isPrefilling)
        }, null, 8, ["modelValue", "is-smart-value", "placeholder", "disabled"]),
        _createVNode(UiInput, {
          highlighted: _unref(isPrefilling),
          modelValue: _unref(state).description,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(state).description) = $event)),
          class: "create-note-modal__area",
          placeholder: _ctx.$t('notes.creating.description_placeholder'),
          type: "textarea",
          rows: "15",
          disabled: _unref(isLoading),
          style: {"resize":"vertical"}
        }, null, 8, ["highlighted", "modelValue", "placeholder", "disabled"])
      ])
    ]),
    footer: _withCtx(() => [
      _createVNode(CreateNoteModalFooter, {
        "is-open-modal": _unref(isOpen),
        "is-submit-loading": _unref(isLoading),
        onClickSubmit: _unref(createNoteStore).create
      }, null, 8, ["is-open-modal", "is-submit-loading", "onClickSubmit"])
    ]),
    _: 1
  }, 8, ["is-open", "no-scroll", "title"]))
}
}

})