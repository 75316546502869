import { RouteRecordRaw } from 'vue-router';
const TrialView = () => import('@/views/trial.vue');
const TrialIndex = () => import('@/views/mobile-app-trial/index.vue');
const TrialTariffId = () => import('@/views/mobile-app-trial/_tariffId.vue');

export default {
  path: '/mobile-app-trial',
  redirect: { name: 'MobileAppTrialIndex' },
  name: 'MobileAppTrialView',
  component: TrialView,
  children: [
    {
      path: '',
      name: 'MobileAppTrialIndex',
      component: TrialIndex,
    },
    {
      path: ':tariffId',
      name: 'MobileAppTrialTariffId',
      component: TrialTariffId,
    },
  ],
  meta: {
    layout: 'default',
    header: false,
    public: true,
  },
} as RouteRecordRaw;
