<template>
  <component :is="layout">
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in" appear>
        <component :is="Component" />
      </transition>
    </router-view>
  </component>
</template>
<script setup lang="ts">
import { useRoute } from 'vue-router';
import { computed, provide, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useSettingsStore } from '@/stores/settings/settings';
import { storeToRefs } from 'pinia';
import { useHead } from '@vueuse/head';
import { useWindowSize } from '@vueuse/core';
import { useIntegrationsStore } from '@/stores/integrations/integrations';
import { useCallStore } from '@/stores/call';

const title = 'Log in to Voiset I seamless task management with AI';
useHead({
  title,
  meta: [
    {
      key: 'og:title',
      property: 'og:title',
      content: title,
    },
  ],
});

const route = useRoute();
const i18t = useI18n();
const { lang } = storeToRefs(useSettingsStore());

const { width: windowWidth } = useWindowSize();
provide('windowWidth', windowWidth);

const isMobileResolution = computed(() => windowWidth.value <= 1100);
provide('isMobileResolution', isMobileResolution);

const DEFAULT_LAYOUT = 'default';
const layout = computed(
  () => (route.meta?.layout || DEFAULT_LAYOUT) + '-layout',
);

const channel = new BroadcastChannel('voiset');
channel.onmessage = async (event) => {
  if (event.data.type === 'zoom-update') {
    const integrations = useIntegrationsStore();
    const call = useCallStore();
    console.log('Zoom auth tab is closed');
    await integrations.fetch();
    if (integrations.getActive('ZOOM')) call.onSetPlatform('ZOOM');
  }
};

watch(
  () => lang.value,
  (newLang) => newLang && (i18t.locale.value = newLang.toLowerCase()),
);
</script>
<style lang="scss">
#app {
  height: 100%;
}
</style>
