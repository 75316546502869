import { ApiService } from '@/api/services/core';
import { axiosInstance } from '@/api/axios';
import { ENDPOINTS } from '@/api/endpoints';
import {
  ICreateTaskRequest,
  Task,
  TaskFilters,
  DayTaskCountsType,
  DescriptionAIParse,
  TaskHighlightRequestParams,
} from '@/types/tasks';
import { Pageable } from '@/types/pagination';
import {
  CallEvent,
  CallEventRemoveParams,
  CallEventRequestParams,
} from '@/types/calls';

export class TasksService extends ApiService {
  fetchTasks(filters: TaskFilters) {
    const { taskDTO, ...params } = filters;
    return axiosInstance.post<Pageable<Task>>(
      ENDPOINTS.tasks.findTasks,
      taskDTO,
      {
        params,
      },
    );
  }

  createTask(createTaskPayload: ICreateTaskRequest) {
    return axiosInstance.post<Task>(
      ENDPOINTS.tasks.createTask,
      createTaskPayload,
    );
  }

  removeTask(taskId: string | number) {
    return axiosInstance.delete(ENDPOINTS.tasks.removeTask(taskId));
  }

  updateTask(createTaskPayload: ICreateTaskRequest, taskId: string | number) {
    const { taskStatus, ...body } = createTaskPayload;
    return axiosInstance.post<Task>(ENDPOINTS.tasks.updateTask(taskId), body, {
      params: { taskStatus },
    });
  }

  getTaskById(id: string) {
    return axiosInstance.get<Task>(ENDPOINTS.tasks.getTask(id));
  }

  updateTaskStatus(id: number, status: string) {
    return axiosInstance.post<Task>(
      ENDPOINTS.tasks.updateStatus(id),
      {},
      {
        params: {
          status,
        },
      },
    );
  }

  getMonthTask(payload: TaskHighlightRequestParams) {
    const { taskDTO, ...params } = payload;
    return axiosInstance.post<Record<string, DayTaskCountsType>>(
      ENDPOINTS.tasks.monthTask,
      taskDTO,
      { params },
    );
  }

  getMonthTaskByUser(params: { userId?: number }) {
    return axiosInstance.post<Record<string, DayTaskCountsType>>(
      ENDPOINTS.tasks.monthTaskByUser,
      null,
      { params },
    );
  }

  getTodoCount() {
    return axiosInstance.get<number>(ENDPOINTS.tasks.todoCount);
  }

  getTasksCountById(id: string | number) {
    return axiosInstance.get<number>(ENDPOINTS.tasks.tasksCount(id));
  }

  sendDescription(description: string) {
    return axiosInstance.post<DescriptionAIParse>(ENDPOINTS.tasks.description, {
      description,
    });
  }

  createCall(payload: CallEventRequestParams) {
    return axiosInstance.post<CallEvent>(ENDPOINTS.calls.create, payload);
  }

  removeCall(data: CallEventRemoveParams) {
    return axiosInstance.delete<CallEvent>(ENDPOINTS.calls.remove, {
      data,
    });
  }

  getCall(requestId: number) {
    return axiosInstance.get<CallEvent>(ENDPOINTS.calls.get, {
      params: { requestId },
    });
  }
}
